import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';

import { DirectAccountDetails, HubblePage, TopNav } from '../../../components';
import { LoginStepView, MFAStepView } from './components';
import { hexToRGB } from '../../../utils/ColorUtil';

const Styles = (theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  failedButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.main,
      backgroundColor: hexToRGB(theme.palette.error.main, 0.1),
    },
  },
});

const View = ({
  classes,
  code,
  errors,
  loginActions,
  mfaActions,
  mfaType,
  openPalms,
  participant,
  question,
  changeQuestion,
  step,
  t,
}) => (
  <>
    <TopNav />
    <HubblePage>
      <Typography variant="h4">{t('Heading')}</Typography>
      <Box mt={2}>
        <Grid container spacing={2}>
          {participant && (
            <>
              <Grid item xs={12} md={6}>
                <Card className={classes.card}>
                  <Box p={2} flexGrow={1}>
                    <Typography variant="h6" paragraph>
                      {t('ParticipantInfo')}
                    </Typography>

                    <Typography variant="caption">
                      {t('ParticipantName')}
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                    >{`${participant.firstName} ${participant.lastName}`}</Typography>

                    <Typography variant="caption">
                      {t('ParticipantID')}
                    </Typography>
                    <Typography variant="body1">
                      {participant.participantId}
                    </Typography>
                  </Box>
                  <Box p={2}>
                    <Button variant="outlined" fullWidth onClick={openPalms}>
                      {t('PalmsAction')}
                    </Button>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card className={classes.card}>
                  <Box p={2} flexGrow={1}>
                    <Typography variant="h6" paragraph>
                      {t('PlanSponsorInfo')}
                    </Typography>

                    <Typography variant="caption">{t('PSName')}</Typography>
                    <Typography variant="body1" paragraph>
                      {participant.account.planSponsor.planSponsorName}
                    </Typography>

                    <Typography variant="caption">{t('Dist')}</Typography>
                    <Typography variant="body1">
                      {
                        participant.account.planSponsor.distributor
                          .distributorName
                      }
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <DirectAccountDetails participantGuid={participant.guid} />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={9}>
            <LoginStepView
              classes={classes}
              expanded={step === 'login'}
              loginActions={loginActions}
              t={t}
              mfaType={mfaType}
              question={question}
              changeQuestion={changeQuestion}
            />
            <MFAStepView
              classes={classes}
              code={code}
              changeQuestion={changeQuestion}
              expanded={step === 'mfa'}
              errors={errors}
              mfaActions={mfaActions}
              mfaType={mfaType}
              question={question}
              t={t}
            />
          </Grid>
        </Grid>
      </Box>
    </HubblePage>
  </>
);

View.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string,
  errors: PropTypes.object.isRequired,
  loginActions: PropTypes.shape({
    noMFA: PropTypes.func,
    failedLogin: PropTypes.func,
    submit: PropTypes.func,
  }).isRequired,
  mfaType: PropTypes.string,
  question: PropTypes.string.isRequired,
  changeQuestion: PropTypes.func.isRequired,
  mfaActions: PropTypes.shape({
    success: PropTypes.func,
    failure: PropTypes.func,
  }).isRequired,
  openPalms: PropTypes.func,
  participant: PropTypes.object.isRequired,
  step: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

View.defaultProps = {
  code: undefined,
  mfaType: undefined,
  openPalms: () => {},
};

export default withStyles(Styles)(View);
