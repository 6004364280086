import { useState } from 'react';
import constate from 'constate';
import config from '../../config';

const InitializePlanSponsor = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchState, setSearchState] = useState('init');
  const [newPlanSponsor, setNewPlanSponsor] = useState();

  const [createState, setCreateState] = useState('init');
  return {
    searchResults,
    searchState,
    newPlanSponsor,
    search: async (name, distributorId) => {
      setSearchState('loading');
      const url = new URL(`${config.pronvest.api.url}Admin/search`);
      url.searchParams.set('name', name);
      url.searchParams.set('distributorId', distributorId);
      const res = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          Authorization: `ApiKey ${config.pronvest.api.apiKey}`,
        },
      });
      if (res.ok) {
        setSearchResults(await res.json());
        setSearchState('loaded');
      }
    },
    create: async (
      distributorId,
      planSponsorName,
      distributorPlanSponsorId,
      feeChargeType,
      billingSchedule
    ) => {
      setCreateState('creating');
      const url = new URL(`${config.pronvest.api.url}Admin/CreatePlanSponsor`);
      url.searchParams.set('name', planSponsorName);
      url.searchParams.set('distributorId', distributorId);
      url.searchParams.set(
        'distributorPlanSponsorId',
        distributorPlanSponsorId
      );
      url.searchParams.set('feeChargeType', feeChargeType);
      url.searchParams.set('billingSchedule', billingSchedule);
      try {
        const res = await fetch(url.toString(), {
          method: 'POST',
          headers: {
            Authorization: `ApiKey ${config.pronvest.api.apiKey}`,
          },
        });
        if (res.ok) {
          setNewPlanSponsor(await res.json());
          setCreateState('created');
          return true;
        }
      } catch {
        setCreateState('err');
      }
      return false;
    },
    createState,
  };
};

const [PlanSponsorProvider, usePlanSponsors] = constate(InitializePlanSponsor);

export default PlanSponsorProvider;
export { usePlanSponsors };
