import React, { useState } from 'react';

import { Button, Checkbox } from '@material-ui/core';

import { Datatable } from '../../components';

export const TradesScheduled = ({
  data,
  sendSMS,
  updateStatus,
  tradesLoading,
}) => {
  const [selected, setSelected] = useState([]);

  const tableConfig = [
    {
      sortable: true,
      heading: 'Status',
      key: 'status',
      formatter: (val) => val,
    },
    {
      sortable: true,
      heading: 'Participant Name',
      key: 'participantName',
    },
    {
      sortable: true,
      heading: 'Participant ID',
      key: 'participantId',
      formatter: (val) => val,
    },
    {
      sortable: true,
      heading: 'Schedule',
      key: 'lastUpdate',
      formatter: (val) => val,
    },
    { heading: 'Product', key: 'product', formatter: (val) => val },
    { heading: 'Type', key: 'type', formatter: (val) => val },
    {
      sortable: true,
      heading: 'Advisor',
      key: 'advisorName',
    },
    {
      heading: 'Send Trade',
      key: 'id',
      formatter: (val) => (
        <Checkbox
          color="primary"
          checked={selected.includes(val)}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          onClick={() => {
            setSelected((prev) =>
              prev?.includes?.(val)
                ? [...prev.filter((i) => i !== val)]
                : [...prev, val]
            );
          }}
        />
      ),
    },
    {
      heading: 'Complete Trade',
      key: 'finished',
      formatter: (val, row) => (
        <Button
          disabled={row.status === 'completed'}
          size="small"
          color="primary"
          onClick={() => {
            updateStatus(val, 'completed');
          }}
        >
          Complete
        </Button>
      ),
    },
  ];

  return (
    <Datatable
      loading={tradesLoading}
      tableHeading="Trades Scheduled"
      defaultSortColumn="created"
      defaultSortDirection="desc"
      data={data}
      tableConfig={tableConfig}
      paginationLeft={true}
      footerContent={
        <Button
          disabled={!(selected?.length > 0)}
          color="primary"
          onClick={() => sendSMS(selected)}
        >
          Send SMS
        </Button>
      }
    />
  );
};
