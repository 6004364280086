import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Button } from '@material-ui/core';
import { HubblePage, TopNav } from '../../components';
import PlanSponsorReportDocument from './PlanSponsorReport-document';
import ManagedAssetsChart from './Charts/ManagedAssetsChart';
import ManagedParticipants from './Charts/ManagedParticipantsChart';
import ManagedParticipantsByDateChart from './Charts/ManagedParticipantsByDateChart';
import ManagedAssetsByDateChart from './Charts/ManagedAssetsByDateChart';
import StrategyBreakdownChart from './Charts/StrategyBreakdownChart';
import InteractionSummaryChart from './Charts/InteractionsSummary';
import StrategySummaryChart from './Charts/StrategySummaryChart';
import GapHiredChart from './Charts/GapHiredChart';

const PlanSponsorReportView = ({ heading, report, chartImages, setter }) => (
  <>
    <TopNav />
    <HubblePage>
      <Box display="flex" justifyContent="space-between" mt={2} mb={3}>
        <Typography variant="h4">{heading}</Typography>
        <Button onClick={window.print}>Print Report</Button>
      </Box>

      <ManagedAssetsChart
        report={report}
        setB64Img={(b) => setter.setManagedAssetsChart(b)}
      />
      <ManagedParticipants
        report={report}
        setB64Img={(b) => setter.setManagedParticipantChart(b)}
      />
      <ManagedParticipantsByDateChart
        report={report}
        setB64Img={(b) => setter.setManagedByDate(b)}
      />
      <ManagedAssetsByDateChart
        report={report}
        setB64Img={(b) => setter.setManagedAssetsByDate(b)}
      />
      <StrategyBreakdownChart
        report={report}
        setB64Img={(b) => setter.setStrategyBreakdown(b)}
      />
      <InteractionSummaryChart
        report={report}
        setB64Img={(b) => setter.setInteractionSummary(b)}
      />
      <StrategySummaryChart
        report={report}
        setB64Img={(b) => setter.setStrategySummary(b)}
      />
      <GapHiredChart report={report} setB64Img={(b) => setter.setGapHired(b)} />

      {Object.values(chartImages).every((x) => x != null) && report && (
        <Box width="100%" height="100vh">
          <PlanSponsorReportDocument
            report={report}
            chartImages={chartImages}
          />
        </Box>
      )}
    </HubblePage>
  </>
);

PlanSponsorReportView.propTypes = {
  heading: PropTypes.string.isRequired,
  report: PropTypes.object,
  chartImages: PropTypes.object,
  setter: PropTypes.object.isRequired,
};

PlanSponsorReportView.defaultProps = {
  report: null,
  chartImages: null,
};

export default PlanSponsorReportView;
