import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar } from '@material-ui/core';

import Hubble from './Hubble.svg';

const Styles = (theme) => ({
  bar: {
    backgroundColor: theme.palette.primary.dark,
  },
});
const TopNav = ({ classes }) => (
  <AppBar className={classes.bar} position="static">
    <Toolbar>
      <Link to="/">
        <img style={{ maxHeight: 32 }} src={Hubble} alt="Hubble" />
      </Link>
    </Toolbar>
  </AppBar>
);
TopNav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(TopNav);
