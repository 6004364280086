import React, { useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Box,
  Card,
  Grid,
  CircularProgress,
  TextField,
  InputAdornment,
  Icon,
  Grow,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useWorkflows } from '../../modules/Workflows';
import { WorkflowListItem } from '../../components';
import TopNav from '../../components/TopNav';
import HubblePage from '../../components/HubblePage';

const Styles = (theme) => ({
  bar: {
    backgroundColor: theme.palette.primary.dark,
  },
  TitleCard: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1, 1, 0, 0),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  toolbarSelect: {
    minWidth: 120,
  },
  cardRow: {
    cursor: 'pointer',
    borderRadius: 0,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
});

const Home = ({ classes }) => {
  const logs = useWorkflows();
  const [items, setItems] = useState([]);
  const [filterString, setFilterString] = useState('');

  useEffect(() => {
    logs.workflowsList.dispatch();
    if (logs.workflowsList.state === 'LOADED') {
      const list = [...logs.workflowsList.data];
      list.sort((x, y) =>
        (x.tags?.simpleName ?? x.name).localeCompare(
          y.tags?.simpleName ?? y.name
        )
      );
      setItems(list);
    }
  }, [logs.workflowsList, logs.workflowsList.state]);

  const failedCount = useMemo(
    () => logs.workflowsList.data.filter((k) => k.state === 'Failed').length,
    [logs.workflowsList]
  );
  const succeededCount = useMemo(
    () => logs.workflowsList.data.filter((k) => k.state === 'Succeeded').length,
    [logs.workflowsList]
  );

  return (
    <>
      <TopNav />
      <HubblePage>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <div>
              <Card className={classes.TitleCard}>
                <Typography variant="overline">Background Processes</Typography>
              </Card>
              <Grow in={logs.workflowsList.state === 'LOADED'} unmountOnExit>
                <Box mt={-2}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon>search</Icon>
                        </InputAdornment>
                      ),
                    }}
                    label="Search"
                    value={filterString}
                    onChange={(e) => setFilterString(e.target.value)}
                  />
                </Box>
              </Grow>
              {logs.workflowsList.state === 'LOADING' && (
                <Card className={classes.cardRow}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress />
                  </Box>
                </Card>
              )}
              {logs.workflowsList.state === 'LOADED' &&
                items
                  .filter(
                    (x) =>
                      filterString === '' ||
                      (x.tags?.simpleName ?? x.name)
                        .toLowerCase()
                        .includes(filterString.toLowerCase())
                  )
                  .map((x) => <WorkflowListItem workflow={x} key={x.name} />)}
            </div>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card>
              <Box p={1}>
                <Typography variant="h6" align="center">
                  {new Date(Date.now()).toLocaleDateString()}
                </Typography>
                {logs.workflowsList.state === 'LOADING' && (
                  <Box
                    py={4}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress />
                  </Box>
                )}
                <Box display="flex" justifyContent="center">
                  {logs.workflowsList.state === 'LOADED' && (
                    <>
                      <Box textAlign="center" p={1} mx={1}>
                        <Typography color="secondary" variant="h2">
                          {succeededCount}
                        </Typography>
                        <Typography variant="subtitle2">Successful</Typography>
                      </Box>
                      <Box textAlign="center" p={1} mx={1}>
                        <Typography color="error" variant="h2">
                          {failedCount}
                        </Typography>
                        <Typography variant="subtitle2">Failed</Typography>
                      </Box>
                      <Box textAlign="center" p={1} mx={1}>
                        <Typography variant="h2">{0}</Typography>
                        <Typography variant="subtitle2">Scheduled</Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </HubblePage>
    </>
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(withRouter(Home));
