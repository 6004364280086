import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';

const LoginStepView = ({
  classes,
  expanded,
  loginActions,
  t,
  mfaType,
  question,
  changeQuestion,
}) => (
  <ExpansionPanel expanded={expanded} onClick={() => {}} variant="elevation">
    <ExpansionPanelSummary>
      <Typography variant="h6">{t('Step1Title')}</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6}>
          <Box>
            {mfaType === 'securityquestion' && (
              <>
                <Typography variant="subtitle1">
                  {t('SecurityQuestionTitle')}
                </Typography>
                <TextField
                  label={t('SecurityQuestionLabel')}
                  value={question}
                  onChange={changeQuestion}
                  fullWidth
                />
              </>
            )}
            <Box mt={2}>
              <Button
                onClick={loginActions.submit}
                fullWidth
                disabled={mfaType === 'securityquestion' && question === ''}
              >
                {t('Submit')}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Divider variant="middle" />
            </Box>
            <Typography color="textSecondary" align="center">
              {t('OR')}
            </Typography>
            <Box flexGrow={1}>
              <Divider variant="middle" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="outlined"
            onClick={loginActions.noMFA}
            color="secondary"
            fullWidth
          >
            {t('NoMFAAction')}
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="outlined"
            onClick={loginActions.failedLogin}
            fullWidth
            className={classes.failedButton}
          >
            {t('FailedLoginAction')}
          </Button>
        </Grid>
      </Grid>
    </ExpansionPanelDetails>
  </ExpansionPanel>
);

LoginStepView.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  loginActions: PropTypes.shape({
    noMFA: PropTypes.func,
    failedLogin: PropTypes.func,
    submit: PropTypes.func,
  }).isRequired,
  mfaType: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  changeQuestion: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

LoginStepView.defaultProps = {
  expanded: false,
};

export default LoginStepView;
