import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import _ from 'lodash';
import Base from '../Base';

const theme = responsiveFontSizes(
  createTheme(
    _.merge(_.cloneDeep(Base), {
      palette: {
        type: 'light',
        common: {
          black: '#323232',
          white: '#FFFFFF',
        },
        primary: {
          main: '#00B8D9',
          light: '#E6FCFF',
          dark: '#0E437F',
          contrastText: '#ffffff'
        },
        secondary: {
          main: '#4B9B55',
          contrastText: '#ffffff'
        },
        error: {
          main: '#DE350B',
          light: '#FF8B00',
        },
        background: {
          default: '#FFFFFF',
        },
        text: {
          primary: '#172B4D',
        },
        accent: {
          purple: '#6554C0',
          yellow: '#FFBE00',
          blue: '#0052CC',
          cyan: '#00B8D9',
          ice: '#F5F7F9',
          green: '#48A54C',
          darkgrey: '#989898',
        },
      },
    })
  )
);

export default theme;
