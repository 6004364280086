import { createBrowserHistory } from 'history';
import Config from '../../config';
// import Segment from '../Segment';

const history = createBrowserHistory({ basename: Config.app.url });

history.listen(() => {
  // Segment.page();
});

export default history;
