/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Config from '../config';
import Logger from '../modules/Logger';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(cp, np) {
    if (this.state.hasError) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, info) {
    Logger.exception(error, info);

    if (!Config.app.development) {
      this.props.history.push('/error');
    }
  }

  render() {
    if (!this.state.hasError) {
      return <>{this.props.children}</>;
    }
    return false;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ErrorBoundary);
