import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Typography,
  Card,
  Box,
  Grid,
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Modal,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HorizontalBar } from 'react-chartjs-2';
import { FixedSizeList } from 'react-window';
import moment from 'moment';
import TopNav from '../../components/TopNav';
import { useWorkflowRuns } from '../../modules/WorkflowRuns';
import HubblePage from '../../components/HubblePage';
import { LogListItem } from '../../components';

const Styles = (theme) => ({
  Chippy: {
    marginRight: theme.spacing(1),
    minWidth: 95,
    color: theme.palette.common.white,
    '&.Succeeded': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.Failed': {
      backgroundColor: theme.palette.error.main,
    },
    '&.Running': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const Workflow = ({ match, classes, history }) => {
  const run = useWorkflowRuns();
  const [details, setDetails] = useState({});
  const [actions, setActions] = useState({});
  const [runList, setRunList] = useState([]);
  const [startEnd, setStartEnd] = useState([]);
  const [id, setId] = useState('');
  const { t } = useTranslation('App.WorkflowPage');

  useEffect(() => {
    run.getRun(match.params.id, match.params.runid).then((d) => {
      setDetails(d);
      setStartEnd([
        new Date(d.json['properties.startTime']),
        new Date(d.json['properties.endTime']),
      ]);
    });
    run.getRunActions(match.params.id, match.params.runid).then((r) => {
      setActions(r);
    });
  }, [match.params, run]);

  useEffect(() => {
    run.getRunList(match.params.id).then((d) => {
      setRunList(d);
    });
  }, [match.params.id, run, id]);

  const allLoaded = details.hasData && actions.hasData && runList.hasData;

  // eslint-disable-next-line react/prop-types
  const LogList = ({ index, style }) => (
    <div style={style}>
      <LogListItem
        idx={index}
        onToggle={() => null}
        key={`${actions.json[index].name}-l-${index}`}
        action={actions.json[index]}
        runId={match.params.runid}
        runName={match.params.id}
      />
    </div>
  );

  const [chartData, setChartData] = useState();
  useEffect(() => {
    if (actions.hasData) {
      setChartData({
        labels: actions.json.map((a) => a.name),
        datasets: [
          {
            label: 'Action Execution',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: actions.json.map((a) => {
              const nil = moment(startEnd[0]);
              const s = moment(new Date(a['properties.startTime']));
              const e = moment(new Date(a['properties.endTime']));
              return [s.diff(nil, 'milliseconds'), e.diff(nil, 'milliseconds')];
            }),
          },
        ],
      });
    }
  }, [actions, startEnd]);

  const duration = useMemo(
    () => ((startEnd[1] - startEnd[0]) / 1000).toFixed(2),
    [startEnd]
  );

  const handleNavChange = (e) => {
    history.push(`/workflow/${match.params.id}/${e.target.value}`);
    setDetails({});
    setActions({});

    setId(e.target.value);
  };

  return (
    <>
      <Modal open={!allLoaded}>
        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      </Modal>
      <TopNav />
      <HubblePage>
        <>
          <Box mb={2}>
            <Card>
              <Box p={1} display="flex">
                <Box>
                  <Typography variant="h4">{match.params.id}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {details.hasData && <>{`Run #${details.json.name}`}</>}
                  </Typography>
                  {details.hasData && (
                    <Typography>{`Duration ${duration} seconds`}</Typography>
                  )}
                </Box>
                {runList.hasData && details.hasData && (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    flex="1"
                    pr={3}
                  >
                    <FormControl style={{ minWidth: 300 }}>
                      <InputLabel>Run Date</InputLabel>
                      <Select value="" displayEmpty onChange={handleNavChange}>
                        {runList.json.map((d) =>
                          d.id === details.json.name ? (
                            <MenuItem value="" key="def" disabled>
                              <Chip
                                className={`${classes.Chippy} ${details.json['properties.status']}`}
                                label={details.json['properties.status']}
                              />
                              {` ${new Date(
                                details.json['properties.startTime']
                              ).toLocaleString()}`}
                            </MenuItem>
                          ) : (
                            <MenuItem value={d.id} key={d.id}>
                              <Chip
                                className={`${classes.Chippy} ${d.status}`}
                                label={d.status}
                              />
                              {` ${new Date(d.runStartTime).toLocaleString()}`}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </Box>
            </Card>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Card>
                <Box p={1}>
                  <Typography variant="h5">{t('Execution')}</Typography>
                  {actions.hasData && chartData && (
                    <HorizontalBar
                      options={{
                        animation: false,
                      }}
                      data={chartData}
                    />
                  )}
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card>
                <Box p={1}>
                  <Typography variant="h5">{t('Archive')}</Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Box my={2}>
            <Card>
              <Box p={1}>
                <Typography variant="h5">{t('Logs')}</Typography>
                {actions && actions.hasData && (
                  <FixedSizeList
                    itemCount={actions.json.length}
                    itemSize={72}
                    height={600}
                  >
                    {LogList}
                  </FixedSizeList>
                )}
              </Box>
            </Card>
          </Box>
        </>
      </HubblePage>
    </>
  );
};

Workflow.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(Styles)(withRouter(Workflow));
