import React, { useState, useMemo, createContext } from 'react';

export const AppContext = createContext(null);

export function AppContextProvider({ children }) {
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const [toastIsVisible, setToastIsVisible] = useState(false);
  const [searchToken, setSearchToken] = useState('');

  const showToast = ({ message, type }) => {
    setToastMessage(message);
    setToastType(type);
    setToastIsVisible(true);
  };

  const provided = useMemo(
    () => ({
      toastMessage,
      setToastMessage: (value) => setToastMessage(value),
      toastType,
      setToastType: (value) => setToastType(value),
      toastIsVisible,
      setToastIsVisible: (value) => setToastIsVisible(value),
      showToast,
      searchToken,
      setSearchToken: (value) => setSearchToken(value),
    }),
    [toastMessage, toastType, toastIsVisible, searchToken]
  );

  return <AppContext.Provider value={provided}>{children}</AppContext.Provider>;
}
