export const mFormat = (n, p = 2) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: p,
    minimumFractionDigits: p,
  }).format(n);

export const nFormat = (n) =>
  Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(n);

export const pFormat = (n) =>
  Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(n / 100);
