import { useState } from 'react';
import constate from 'constate';
import config from '../../config';

const Initialize = () => {
  const [participant, setParticipant] = useState();
  const [loadState, setLoadState] = useState('init');
  const getParticipant = async (id) => {
    const url = new URL(`${config.pronvest.api.url}Admin/participant/${id}`);
    const res = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        Authorization: `ApiKey ${config.pronvest.api.apiKey}`,
      },
    });
    if (res.ok) {
      setParticipant(await res.json());
      setLoadState('loaded');
    }
  };
  const switchPlanSponsor = async (id, plansponsorId) => {
    setLoadState('loading');
    const url = new URL(
      `${config.pronvest.api.url}Admin/participant/${id}/AssignNewPlanSponsor/${plansponsorId}`
    );
    const res = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        Authorization: `ApiKey ${config.pronvest.api.apiKey}`,
      },
    });
    if (res.ok) {
      setParticipant(await res.json());
      setLoadState('loaded');
      return true;
    }
    return false;
  };
  return {
    participant,
    loadState,
    getParticipant,
    switchPlanSponsor,
  };
};

const [ParticipantProvider, useParticipant] = constate(Initialize);

export default ParticipantProvider;
export { useParticipant };
