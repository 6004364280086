import Session from '../Session';
import { HUBBLE_SESSION_AUTH } from '../Login';

const defaultHeaders = () => {
  const { token } = Session.get(HUBBLE_SESSION_AUTH) || {};
  return { Authorization: `Bearer ${token}` };
};

// eslint-disable-next-line import/prefer-default-export
export { defaultHeaders };
