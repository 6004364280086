import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import Config from '../../config';

Sentry.init({
  dsn: Config.loggers.sentry.dsn,
  environment: Config.loggers.sentry.environment,
});

LogRocket.getSessionURL((sessionURL) => {
  Sentry.configureScope((scope) => {
    scope.setTag('LogRocketSession', sessionURL);
  });
});

const logMessage = (level, message, info = {}) => {
  Sentry.withScope((scope) => {
    Object.keys(info).forEach((key) => {
      scope.setExtra(key, info[key]);
    });
    Sentry.captureMessage(message, level);
  });
};

const logException = (error, errorInfo) => {
  Sentry.withScope((scope) => {
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key]);
    });
    Sentry.captureException(error);
  });
};

export default {
  debug: (message, info = {}) => logMessage('debug', message, info),
  error: (message, info = {}) => logMessage('error', message, info),
  fatal: (message, info = {}) => logMessage('fatal', message, info),
  info: (message, info = {}) => logMessage('info', message, info),
  trace: (message, info = {}) => logMessage('trace', message, info),
  warn: (message, info = {}) => logMessage('warn', message, info),
  exception: logException,
};
