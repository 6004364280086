import { useState } from 'react';
import constate from 'constate';
import config from '../../config';
import { defaultHeaders } from './utils';

const Initialize = () => {
  const [data, setData] = useState();
  const [loadState, setLoadState] = useState('init');

  const load = (id) => {
    setLoadState('loading');
    const url = new URL(
      `${config.pronvest.api.url}Admin/participant/${id}/credentials`
    );
    fetch(url.toString(), {
      method: 'GET',
      headers: {
        ...defaultHeaders(),
      },
    }).then(async (d) => {
      setData(await d.json());
      setLoadState('loaded');
    });
  };

  const updateDirectAccountMfaType = async (id, type) => {
    setLoadState('loading');
    const url = new URL(
      `${config.pronvest.api.url}Admin/participant/${id}/DirectMFAType`
    );
    await fetch(url.toString(), {
      method: 'PUT',
      headers: {
        ...defaultHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(type),
    });
    setLoadState('loaded');
  };

  const updateDirectAcctStatus = async (id, status) => {
    setLoadState('loading');
    const url = new URL(
      `${config.pronvest.api.url}Admin/participant/${id}/DirectAccountStatus/${status}`
    );
    await fetch(url.toString(), {
      method: 'PUT',
      headers: {
        ...defaultHeaders(),
      },
    });
    setLoadState('loaded');
  };

  return {
    data,
    updateDirectAcctStatus,
    updateDirectAccountMfaType,
    loadState,
    load,
  };
};

const [DirectDetailsProvider, useDirectDetails] = constate(Initialize);

export default DirectDetailsProvider;
export { useDirectDetails };
