import React from 'react';
import {
  makeStyles,
  Modal,
  IconButton,
  Button,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      maxWidth: 1080,
      margin: 'auto',
    },
  },
  modalWrapper: {
    padding: theme.spacing(0, 3, 0),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  modalContainer: {
    justifyContent: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: 500,
    backgroundColor: '#fff',
    borderRadius: '15px',
    boxShadow: '0 0 15px 5px rgba(0,0,0,0.1)',
    padding: theme.spacing(2, 4, 4),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  modalTitle: {
    fontSize: '1.5rem',
  },
  modalDescription: {
    fontSize: '1rem',
    margin: '0',
  },
  subtitle: {
    marginBottom: 0,
    fontWeight: 'bold',
    color: '#666',
  },
  modalFinePrint: {
    fontSize: '0.7rem',
    color: '#666',
    margin: '0 auto',
    width: '60%',
    lineHeight: '1.5',
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  modalButtonBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    '& button': {
      width: '60%',
      marginBottom: theme.spacing(2),
    },
  },
  buttonProgress: {
    '& .MuiCircularProgress-circleIndeterminate': {
      stroke: '#fff !important',
    },
  },
}));

export const BaseModal = ({
  isOpen,
  handleClose,
  title,
  description,
  fineprint,
  content,
  customPrimaryAction,
  primaryActionText,
  primaryActionColor = 'primary',
  primaryActionLoading = false,
  handlePrimaryAction,
  secondaryActionText,
  handleSecondaryAction,
  customSecondaryAction,
}) => {
  const classes = useStyles();

  return (
    <Modal
      BackdropProps={{
        style: {
          background: 'rgba(200,200,200,0.6)',
        },
      }}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalContainer}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <div className={classes.modalWrapper}>
          <h2 className={classes.modalTitle} id="simple-modal-title">
            {title}
          </h2>
          <p id="simple-modal-description" className={classes.modalDescription}>
            {description}
          </p>
          {content}
          <div className={classes.modalButtonBox}>
            {primaryActionText && (
              <Button color={primaryActionColor} onClick={handlePrimaryAction}>
                {primaryActionLoading ? (
                  <CircularProgress
                    color="primary"
                    size={24}
                    className={classes.buttonProgress}
                  />
                ) : (
                  <>{primaryActionText}</>
                )}
              </Button>
            )}
            {customPrimaryAction && customPrimaryAction}
            {secondaryActionText && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSecondaryAction}
              >
                {secondaryActionText}
              </Button>
            )}
            {customSecondaryAction && customSecondaryAction}
          </div>
          <p className={classes.modalFinePrint}>{fineprint}</p>
        </div>
      </div>
    </Modal>
  );
};
