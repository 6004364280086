import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';
import ChartImager from '../../../components/ChartImager';

const ManagedParticipantsByDateChart = ({ report, setB64Img }) => {
  const theme = useTheme();

  const CONFIG = {
    options: {
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              suggestedMin: 0,
            },
          },
        ],
      },
      animation: {
        duration: 0,
      },
    },
  };

  const ds = {
    datasets: [
      {
        label: 'Breakdown By Strategy',
        data: report.strategies.map((x) => x.count),
        backgroundColor: theme.palette.primary.main,
      },
    ],
    labels: report.strategies.map((x) => x.strategy),
  };
  return (
    <ChartImager
      callback={setB64Img}
      ChartComponent={Bar}
      data={ds}
      options={CONFIG.options}
    />
  );
};

ManagedParticipantsByDateChart.propTypes = {
  report: PropTypes.object.isRequired,
  setB64Img: PropTypes.func.isRequired,
};

export default ManagedParticipantsByDateChart;
