import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
  withStyles,
  TextField,
} from '@material-ui/core';

const WaitingStyles = (theme) => ({
  cp: {
    margin: theme.spacing(3),
  },
});

const WaitingView = withStyles(WaitingStyles)(({ classes, t }) => {
  const [timeWaiting, setTimeWaiting] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeWaiting(timeWaiting + 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeWaiting]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Typography variant="h6" paragraph>
        {t('WaitingTitle', {
          minutes: Math.floor(timeWaiting / 60),
          seconds: timeWaiting % 60,
        })}
      </Typography>
      <CircularProgress
        className={classes.cp}
        disableShrink
        size={76}
        variant="indeterminate"
        color="secondary"
      />
    </Box>
  );
});

WaitingView.propTypes = { t: PropTypes.func.isRequired };

const CodeView = ({ classes, code, mfaActions, t }) => (
  <>
    <Grid container spacing={2} justify="center">
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Typography variant="h6" paragraph>
            {t('CodeTitle')}
          </Typography>
          <Typography variant="h4" paragraph>
            {code}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Button onClick={mfaActions.success} fullWidth>
          {t('SuccessAction')}
        </Button>
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          onClick={mfaActions.failure('expired')}
          fullWidth
          variant="outlined"
          className={classes.failedButton}
        >
          {t('FailureAction')}
        </Button>
      </Grid>
    </Grid>
  </>
);

CodeView.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string.isRequired,
  mfaActions: PropTypes.shape({
    success: PropTypes.func,
    failure: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

const AnswerView = ({
  classes,
  code,
  changeQuestion,
  mfaActions,
  t,
  question,
}) => (
  <>
    <Grid container spacing={2} justify="center">
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Box mb={2} width="100%">
            <TextField
              label={t('SecurityQuestionLabel')}
              value={question}
              onChange={changeQuestion}
              fullWidth
            />
          </Box>
          <Typography variant="h4" paragraph>{`"${code}"`}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Button onClick={mfaActions.success} fullWidth>
          {t('SuccessAction')}
        </Button>
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          onClick={mfaActions.failure('incorrect')}
          fullWidth
          variant="outlined"
          className={classes.failedButton}
        >
          {t('SQFailureAction')}
        </Button>
      </Grid>
    </Grid>
  </>
);

AnswerView.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string.isRequired,
  changeQuestion: PropTypes.func.isRequired,
  mfaActions: PropTypes.shape({
    success: PropTypes.func,
    failure: PropTypes.func,
  }).isRequired,
  question: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const ErrorView = ({ classes, failureAction, t }) => {
  const [timeWaiting, setTimeWaiting] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeWaiting(timeWaiting + 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeWaiting]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Typography variant="h6" paragraph>
        {t('ErrorTitle')}
      </Typography>
      <Button
        onClick={failureAction('newcode')}
        fullWidth
        variant="outlined"
        className={classes.failedButton}
      >
        {t('FailureAction')}
      </Button>
    </Box>
  );
};

ErrorView.propTypes = {
  classes: PropTypes.object.isRequired,
  failureAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const MFAStepView = ({
  classes,
  code,
  changeQuestion,
  errors,
  expanded,
  mfaActions,
  mfaType,
  question,
  t,
}) => {
  let componentToRender = <Box />;
  if (expanded) {
    if (errors.failed) {
      componentToRender = (
        <ErrorView classes={classes} failureAction={mfaActions.failure} t={t} />
      );
    } else if (code == null) {
      componentToRender = <WaitingView t={t} />;
    } else if (mfaType === 'twofactor') {
      componentToRender = (
        <CodeView code={code} classes={classes} mfaActions={mfaActions} t={t} />
      );
    } else {
      componentToRender = (
        <AnswerView
          code={code}
          classes={classes}
          changeQuestion={changeQuestion}
          mfaActions={mfaActions}
          question={question}
          t={t}
        />
      );
    }
  }

  return (
    <ExpansionPanel expanded={expanded} onClick={() => {}}>
      <ExpansionPanelSummary>
        <Typography variant="h6">{t('Step2Title')}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{componentToRender}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

MFAStepView.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string,
  changeQuestion: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  mfaActions: PropTypes.shape({
    success: PropTypes.func,
    failure: PropTypes.func,
  }).isRequired,
  mfaType: PropTypes.string.isRequired,
  question: PropTypes.string,
  t: PropTypes.func.isRequired,
};

MFAStepView.defaultProps = {
  code: undefined,
  expanded: false,
  question: '',
};

export default MFAStepView;
