import createContainer from 'constate';
import config from '../../config';
import { requests } from '../../utils/DataUtil';

const speakeasyURL = `${config.pronvest.api.url}Speakeasy`;

const SpeakeasyTrades = () => {
  return {
    getTrades: () => requests.GET(`${speakeasyURL}/Jobs`),
    getJobStatus: (jobId) => requests.GET(`${speakeasyURL}/${jobId}/Status`),
    // This endpoint is what sends the SMS tokens.
    // accepts a list of job GUIDS in the body.
    sendInvite: (jobGuids) => requests.POST(`${speakeasyURL}/Invite`, jobGuids),
    createJob: (participantId, type) =>
      requests.POST(`${speakeasyURL}/Job/Hubble`, { participantId, type }),
    /*
      Available values : pending, active, readyForCode, codeReceived, completed, scheduled, expired
      */
    updateStatus: (jobId, status) =>
      requests.PUT(
        `${speakeasyURL}/${jobId}/Status/Hubble?newStatus=${status}`
      ),
  };
};

const [Provider, useSpeakeasyTrades] = createContainer(SpeakeasyTrades);

export { useSpeakeasyTrades };

export default Provider;
