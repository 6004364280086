import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { LoadingView } from '../../components';

import Config from '../../config';
import Session from '../../modules/Session';
import { HUBBLE_SESSION_AUTH, LOGIN_STATES } from '../../modules/Login';

const Login = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const { loginStatus, redirect, token } =
      Session.get(HUBBLE_SESSION_AUTH) || {};

    const doLogin = (s, t, r) => {
      Session.set(HUBBLE_SESSION_AUTH, {
        token: t,
        loginStatus: s,
        redirect: r,
      });
      history.push(redirect || '/');
    };

    const doFetch = async () => {
      const queryParams = queryString.parse(location.search);
      const res = await fetch(
        `${
          Config.pronvest.api.url
        }AzureAD/Exchange/Hubble?${queryString.stringify(queryParams)}`,
        {
          method: 'GET',
        }
      );
      const r = await res.json();
      doLogin(LOGIN_STATES.loggedin, r.access_Token, redirect);
    };

    if (loginStatus !== LOGIN_STATES.loggedin) {
      doFetch();
    } else {
      doLogin(loginStatus, token, redirect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingView message="Logging you in..." />;
};

export default Login;
