import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Card,
  Grid,
  Link,
  Tabs,
  Tab,
  Typography,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { DirectAccountDetails, HubblePage, TopNav } from '../../components';
import ReviewView from './Review-view';
import SearchView from './Search-view';

const Styles = (theme) => ({
  changePSLink: {
    margin: theme.spacing(0, 0, 0, 2),
    verticalAlign: 'middle',
  },
  searchResultRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
});

const ConfigureFundLineupView = ({
  classes,
  t,
  tab,
  setTab,
  participant,
  match,
  tickerList,
  handleTicker,
  tickerInput,
  appendTickerList,
  removeIdx,
  fundParse,
  parsedFunds,
  assetClasses,
  // Fund Objects to Submit
  fluFuncs,
  conflictFunds,
  addFunds,
  removeFunds,
  createFunds,

  confirm,
  confirmStatus,

  openPalms,

  searchResults,
  searchVisible,
  searchToggle,
}) => (
  <>
    <TopNav />
    <HubblePage>
      <Typography variant="h4">{t('Heading')}</Typography>
      {participant && (
        <Box mt={2}>
          <Card>
            <Box p={2}>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Typography variant="h6" paragraph>
                    {t('PlanSponsorInfo')}
                  </Typography>
                  <Typography variant="caption">{t('PSName')}</Typography>
                  <Box mb={2}>
                    <Typography variant="body1" component="span">
                      {participant.account.planSponsor.planSponsorName}
                    </Typography>
                    <Link
                      underline="hover"
                      className={classes.changePSLink}
                      component={RouterLink}
                      to={`/plansponsor/${match.params.id}`}
                    >
                      {t('ChangePlanSponsor')}
                    </Link>
                  </Box>
                  <Typography variant="caption">{t('Dist')}</Typography>
                  <Typography variant="body1">
                    {
                      participant.account.planSponsor.distributor
                        .distributorName
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="h6" paragraph>
                    {t('ParticipantInfo')}
                  </Typography>
                  <Box mb={2}>
                    <Typography variant="caption">
                      {t('ParticipantName')}
                    </Typography>
                    <Typography variant="body1">{`${participant.firstName} ${participant.lastName}`}</Typography>
                  </Box>
                  <Typography variant="caption">
                    {t('ParticipantID')}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {participant.participantId}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Box>
      )}
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <DirectAccountDetails participantGuid={match.params.id} />
          </Grid>
          <Grid item xs={12} md={9}>
            <Card style={{ overflow: 'unset' }}>
              <AppBar position="static">
                <Tabs value={tab} indicatorColor="secondary">
                  <Tab
                    value="SEARCH"
                    label={t('BuildHeading')}
                    onClick={() => setTab('SEARCH')}
                  />
                  <Tab
                    value="CONFIRM"
                    disabled
                    label={t('CompareHeading')}
                    onClick={() => setTab('CONFIRM')}
                  />
                </Tabs>
              </AppBar>
              {tab === 'SEARCH' && (
                <SearchView
                  classes={classes}
                  t={t}
                  setTab={setTab}
                  tickerList={tickerList}
                  handleTicker={handleTicker}
                  tickerInput={tickerInput}
                  appendTickerList={appendTickerList}
                  removeIdx={removeIdx}
                  fundParse={fundParse}
                  searchResults={searchResults}
                  searchVisible={searchVisible}
                  searchToggle={searchToggle}
                />
              )}
              {tab === 'CONFIRM' && (
                <ReviewView
                  t={t}
                  parsedFunds={parsedFunds}
                  assetClasses={assetClasses}
                  openPalms={openPalms}
                  fluFuncs={fluFuncs}
                  conflictFunds={conflictFunds}
                  addFunds={addFunds}
                  removeFunds={removeFunds}
                  createFunds={createFunds}
                  confirm={confirm}
                  confirmStatus={confirmStatus}
                />
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>
    </HubblePage>
  </>
);

ConfigureFundLineupView.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tab: PropTypes.string,
  participant: PropTypes.object,
  match: PropTypes.object.isRequired,
  setTab: PropTypes.func.isRequired,
  tickerList: PropTypes.array.isRequired,
  handleTicker: PropTypes.func.isRequired,
  tickerInput: PropTypes.string,
  appendTickerList: PropTypes.func.isRequired,
  removeIdx: PropTypes.func.isRequired,
  fundParse: PropTypes.func.isRequired,
  parsedFunds: PropTypes.object,
  assetClasses: PropTypes.arrayOf(PropTypes.string).isRequired,

  fluFuncs: PropTypes.object.isRequired,
  conflictFunds: PropTypes.object,
  addFunds: PropTypes.object,
  removeFunds: PropTypes.object,
  createFunds: PropTypes.object,

  confirm: PropTypes.func.isRequired,
  confirmStatus: PropTypes.string.isRequired,

  openPalms: PropTypes.func.isRequired,

  searchResults: PropTypes.array.isRequired,
  searchVisible: PropTypes.bool.isRequired,
  searchToggle: PropTypes.func.isRequired,
};

ConfigureFundLineupView.defaultProps = {
  participant: null,
  tab: 'SEARCH',
  tickerInput: '',
  parsedFunds: null,
  conflictFunds: null,
  addFunds: null,
  removeFunds: null,
  createFunds: null,
};

export default withStyles(Styles)(ConfigureFundLineupView);
