import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Config from '../../config';

const Login = () => {
  useEffect(() => {
    window.location = `${Config.pronvest.api.url}AzureAD/OauthLogin/Hubble`;
  });
  return <>Logging In...</>;
};
export default withRouter(Login);
