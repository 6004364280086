import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useSignalR } from '../../../modules/SignalR';
import {
  useParticipant,
  useDirectDetails,
  useMFA,
} from '../../../modules/admin';
import Config from '../../../config';

import { LoadingView } from '../../../components';
import View from './Assist-view';

const Container = ({ match, location, history }) => {
  const signalR = useSignalR();
  const mfaAPI = useMFA();
  const [queueType] = useState(location.state);
  const [step, setStep] = useState('login');
  const [mfaType, setMfaType] = useState('twofactor');
  const [question, setQuestion] = useState('');
  const [code, setCode] = useState();
  const [errors, setErrors] = useState({});
  const { t } = useTranslation('App.MFA.Assist');
  const { participant, getParticipant } = useParticipant();
  const {
    data: directAccountDetails,
    loadState: dadState,
    load: loadDad,
  } = useDirectDetails();

  const { participantGuid } = match.params;

  useEffect(() => {
    if (participant == null && participantGuid) {
      getParticipant(participantGuid);
    }
  }, [getParticipant, participantGuid, participant]);

  useEffect(() => {
    if (dadState === 'init' && participantGuid) {
      loadDad(participantGuid);
    } else if (dadState === 'loaded') {
      setMfaType(
        directAccountDetails?.yodleeAccount?.yodleeLink.mfaType.toLowerCase()
      );
    }
  }, [loadDad, dadState, directAccountDetails, participantGuid]);

  useEffect(() => {
    if (!signalR.isConnected()) {
      signalR.reset();
      history.push('/mfa/queue');
    }
  }, [history, signalR]);

  useEffect(() => {
    if (signalR.state === 'code' || signalR.state === 'answer') {
      setCode(signalR.data);
      setErrors({});
      signalR.reset();
    } else if (signalR.state === 'didnotreceive') {
      setCode();
      setErrors({
        failed: true,
      });
    } else if (signalR.state === 'interrupted') {
      signalR.exitConversation();
      history.push('/mfa/queue');
    }
  }, [history, signalR]);

  const finishAssisting = async () => {
    await mfaAPI.finishAssisting(participantGuid);
    signalR.reset();
    signalR.exitConversation();
  };

  const loginActions = {
    noMFA: () => {
      signalR.success();
      finishAssisting();
      history.push('/mfa/queue');
    },
    failedLogin: () => {
      signalR.failure();
      finishAssisting();
      history.push('/mfa/queue');
    },
    submit: () => {
      if (mfaType === 'twofactor') {
        signalR.assist('twofactor', queueType);
      } else if (mfaType === 'securityquestion') {
        signalR.assist('securityquestion', queueType, question);
      }
      setStep('mfa');
    },
  };

  const mfaActions = {
    success: () => {
      if (mfaType === 'securityquestion') {
        mfaAPI.saveSecurityQuestion(participantGuid, question, code);
      }
      signalR.success();
      finishAssisting();
      if (location.state === 'Trade') {
        openPalms();
        history.push('/mfa/queue');
      } else {
        history.push(`/fundlineup/${participantGuid}`);
      }
    },
    failure: (error) => () => {
      signalR.retry(
        mfaType,
        error,
        mfaType === 'securityquestion' ? question : undefined
      );
      signalR.reset();
      setCode();
      setErrors({});
    },
  };

  const openPalms = useCallback(() => {
    const { participantId } = participant || {};
    window.open(
      `${Config.pronvest.palms.url}participants/participant.aspx?id=${participantId}`,
      '_blank'
    );
  }, [participant]);

  const changeQuestion = (event) => setQuestion(event.target.value);

  const props = {
    code,
    errors,
    loginActions,
    mfaActions,
    mfaType,
    openPalms,
    participant,
    question,
    changeQuestion,
    step,
    t,
  };

  if (!(participant && directAccountDetails && signalR.inConversation())) {
    return <LoadingView message={t('Loading')} />;
  }

  return <View {...props} />;
};

Container.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Container);
