export default {
  analytics: {
    segment: {
      writeKey:
        process.env.REACT_APP_SEGMENT_WRITE_KEY ||
        'EbfXGjeFn1paXEjL0Wie5jui5hggprwa',
    },
    logrocket: {
      apiKey: process.env.REACT_APP_LOGROCKET_API_KEY || 'wfndjr/dev-sy9pl',
    },
  },
  app: {
    development: process.env.NODE_ENV === 'development',
    name: 'hubble',
    url: process.env.PUBLIC_URL || '/',
  },
  auth: {
    google: {
      captchaSiteKey:
        process.env.REACT_APP_GOOGLE_CAPTCHA ||
        '6LcqarEUAAAAAD4MliCX7SmNKKPwIxRle4cpxLaR',
    },
  },
  calendly: {
    url: 'https://calendly.com/pronvest-service-center/retirement-consultation',
  },
  loggers: {
    sentry: {
      dsn:
        process.env.REACT_APP_SENTRY_DSN ||
        'https://1b577e1516184e7c8e98983d0601d642@o230777.ingest.sentry.io/1879673',
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'dev',
    },
  },
  pronvest: {
    api: {
      apiKey: '57ea68e8-abe1-49b9-8f4f-a326411312ec',
      url:
        process.env.REACT_APP_PRONVEST_API_URL || 'https://localhost:5001/v1/',
    },
    palms: {
      url:
        process.env.REACT_APP_PRONVEST_PALMS_URL || 'http://localhost:61905/',
    },
    planner: {
      url:
        process.env.REACT_APP_PRONVEST_PLANNER_URL || 'http://localhost:51065/',
    },
    signalR: {
      url:
        process.env.REACT_APP_SIGNALR_URL ||
        'https://localhost:5001/OutpostHub',
    },
    signup: {
      url:
        process.env.REACT_APP_PRONVEST_SIGNUP_URL ||
        'http://localhost:3000/launchpad/signup/',
    },
    sessionStorage: {
      prefix: 'Hubble_',
    },
  },
};
