import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDirectDetails } from '../../modules/admin';

import DirectAccountDetailsView from './DirectAccountDetails-view';

const DirectAccountDetailsContainer = ({ participantGuid }) => {
  const { t } = useTranslation('Component.DirectAccountDetails');
  const { data, updateDirectAccountMfaType, updateDirectAcctStatus, load } =
    useDirectDetails();

  const [acctLinkState, setAccountLinkState] = useState(0);
  const [mfaType, setMfaType] = useState('SimpleAuth');
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if (data != null) {
      setAccountLinkState(data.failedLogin ? 0 : 1);
      setMfaType(data.yodleeAccount?.yodleeLink?.mfaType);
    }
  }, [data]);

  const saveLoginStatusAndMFAType = async () => {
    setDisableSave(true);
    if (mfaType !== data.mfaType) {
      await updateDirectAccountMfaType(participantGuid, mfaType);
    }
    if ((acctLinkState === 0) !== data.failedLogin) {
      await updateDirectAcctStatus(participantGuid, acctLinkState);
    }
    await load(participantGuid);
    setDisableSave(false);
  };

  return (
    <DirectAccountDetailsView
      distributorAccountId={data?.distributorAccountId}
      disableSave={disableSave}
      loadDirectDetails={() => load(participantGuid)}
      yodleeLink={data?.yodleeAccount?.yodleeLink}
      mfaType={mfaType}
      acctLinkState={acctLinkState}
      changeMfaType={(e) => setMfaType(e.target.value)}
      changeLinkState={(e) => setAccountLinkState(e.target.value)}
      saveLoginStatusAndMFAType={saveLoginStatusAndMFAType}
      t={t}
    />
  );
};

DirectAccountDetailsContainer.propTypes = {
  participantGuid: PropTypes.string.isRequired,
};

export default DirectAccountDetailsContainer;
