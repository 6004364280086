import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ConfigureFundLineupView from './ConfigureFundLineup-view';
import {
  useParticipant,
  useFundBuilder,
  usePlanSponsors,
  searchFunds,
} from '../../modules/admin';
import Config from '../../config';

const ASSET_CLASSES = [
  'Other',
  'Bonds',
  'Small Cap Stock',
  'World Stock',
  'Short Term Investment',
  'International Stock',
  'Mid Cap Stock',
  'Company Stock',
  'Large Cap Stock',
  'Real Estate',
  'Lifestyle Funds',
  'TIPS',
  'Loan',
];

const ConfigureFundLineupContainer = ({ match }) => {
  const { participant, getParticipant } = useParticipant();
  const { parseTickers, parsedFunds, confirmFunds, confirmStatus } =
    useFundBuilder();
  const { create } = usePlanSponsors();

  const { t } = useTranslation('App.ConfigureFundLineup');

  const [currentTab, setCurrentTab] = useState('SEARCH');
  const [tickerList, setTickerList] = useState([]);

  useEffect(() => {
    getParticipant(match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appendTickerList = (tick) => {
    if (tick != null && tick !== '') {
      setTickerList(tickerList.concat(tick.toUpperCase()));
    }
  };

  const removeIdx = (i) => {
    const arr = tickerList.filter((x, idx) => idx !== i);
    setTickerList(arr);
  };

  const doFundParse = () =>
    parseTickers(tickerList, participant.account.planSponsor.fundLineUpId);

  const [addFunds, setAddFunds] = useState();
  const [conflictFunds, setConflictFunds] = useState();
  const [removeFunds, setRemoveFunds] = useState();
  const [createFunds, setCreateFunds] = useState();
  const fluFuncs = {
    setAddFunds,
    setRemoveFunds,
    setCreateFunds,
    setConflictFunds,
  };

  useEffect(() => {
    if (parsedFunds) {
      const obj = {
        conflict: {},
        add: {},
        remove: {},
        create: {},
      };
      Object.keys(parsedFunds.conflict).forEach((k) => {
        obj.conflict[k] = { confirmed: false };
      });
      Object.keys(parsedFunds.add).forEach((k) => {
        obj.add[k] = {
          confirmed: false,
          fundId: parsedFunds.add[k][0].fundId,
        };
      });
      Object.keys(parsedFunds.remove).forEach((k) => {
        obj.remove[k] = {
          confirmed: false,
          fundId: parsedFunds.remove[k][0].fundId,
        };
      });
      Object.keys(parsedFunds.create).forEach((k) => {
        obj.create[k] = { confirmed: false };
      });
      setConflictFunds(obj.conflict);
      setAddFunds(obj.add);
      setRemoveFunds(obj.remove);
      setCreateFunds(obj.create);
    }
  }, [parsedFunds]);

  const prepareRequest = () => {
    const body = {
      conflict: {},
      add: {},
      remove: {},
      create: {},
    };
    Object.keys(addFunds).forEach((k) => {
      if (addFunds[k].confirmed) {
        body.add[k] = addFunds[k].fundId;
      }
    });
    Object.keys(conflictFunds).forEach((k) => {
      if (conflictFunds[k].confirmed) {
        body.conflict[k] = conflictFunds[k].fundId;
      }
    });
    Object.keys(removeFunds).forEach((k) => {
      if (removeFunds[k].confirmed) {
        body.remove[k] = removeFunds[k].fundId;
      }
    });
    Object.keys(createFunds).forEach((k) => {
      if (createFunds[k].confirmed) {
        body.create[k] = {
          ticker: k,
          fundName: createFunds[k].fundName,
          assetClassId: createFunds[k].assetClassId,
          cusip: createFunds[k].cusip,
        };
      }
    });
    confirmFunds(body, participant.account.planSponsor.fundLineUpId);
  };

  const openPalms = () => {
    window.location = `${Config.pronvest.palms.url}participants/participant.aspx?id=${participant.participantId}`;
  };

  const [fundResults, setFundResults] = useState([]);
  const [searchVisible, showSearch] = useState(false);
  const [tickerInput, setTickerInput] = useState('');

  const tickerInputHandler = (e) => {
    setTickerInput(e.target.value);
  };

  useEffect(() => {
    if (tickerInput.length > 1) {
      const timeout = setTimeout(async () => {
        setFundResults(await searchFunds(tickerInput));
        showSearch(true);
      }, 250);

      return () => clearTimeout(timeout);
    }

    showSearch(false);
    return () => {};
  }, [tickerInput]);

  return (
    <ConfigureFundLineupView
      t={t}
      participant={participant}
      match={match}
      tab={currentTab}
      setTab={setCurrentTab}
      onCreate={create}
      handleTicker={tickerInputHandler}
      tickerInput={tickerInput}
      appendTickerList={appendTickerList}
      removeIdx={removeIdx}
      tickerList={tickerList}
      fundParse={doFundParse}
      parsedFunds={parsedFunds}
      assetClasses={ASSET_CLASSES}
      fluFuncs={fluFuncs}
      conflictFunds={conflictFunds}
      createFunds={createFunds}
      addFunds={addFunds}
      removeFunds={removeFunds}
      confirm={prepareRequest}
      confirmStatus={confirmStatus}
      openPalms={openPalms}
      searchResults={fundResults}
      searchVisible={searchVisible}
      searchToggle={(v) => showSearch(v)}
    />
  );
};

ConfigureFundLineupContainer.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(ConfigureFundLineupContainer);
