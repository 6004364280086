import React from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  Paper,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';

const SearchView = ({
  classes,
  t,
  setTab,
  tickerList,
  handleTicker,
  tickerInput,
  appendTickerList,
  removeIdx,
  fundParse,
  searchResults,
  searchVisible,
  searchToggle,
}) => (
  <Box p={2}>
    <Typography variant="h5" paragraph>
      {t('BuildHeading')}
    </Typography>
    <Box mb={2}>
      {t('CustomFunds', { returnObjects: true }).map((x, i) => (
        <Typography key={i} variant="body2">
          {x}
        </Typography>
      ))}
    </Box>
    <Box position="relative" display="flex" width="100%" mb={1}>
      <ClickAwayListener onClickAway={() => searchToggle(false)}>
        <Box width="100%">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              appendTickerList();
              handleTicker({ target: { value: '' } });
            }}
            style={{ width: '100%' }}
          >
            <Box display="flex" flexDirection="row">
              <Box flex="1">
                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="search-term">
                    {t('SearchAction')}
                  </InputLabel>
                  <FilledInput
                    id="search-term"
                    type="text"
                    autoComplete="off"
                    value={tickerInput}
                    onFocus={() => {
                      if (tickerInput.length > 1) {
                        searchToggle(true);
                      }
                    }}
                    onChange={handleTicker}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            </Box>
          </form>
          <Box
            hidden={!searchVisible}
            mt={1}
            zIndex={3}
            top="100%"
            width="100%"
            position="absolute"
          >
            <Paper elevation={3}>
              <Box p={2}>
                {searchResults
                  .filter((x) => x.ticker)
                  .map((x, i) =>
                    i < 10 ? (
                      <Box
                        key={`${i}-${x.ticker}`}
                        className={classes.searchResultRow}
                        p={1}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => {
                          if (!tickerList.includes(x.ticker.toUpperCase())) {
                            appendTickerList(x.ticker);
                            searchToggle(false);
                            handleTicker({ target: { value: '' } });
                          }
                        }}
                      >
                        <Box mr={3}>
                          <Typography variant="h6">{x.ticker}</Typography>
                        </Box>
                        <Box
                          flex={1}
                          style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          <abbr title={x.fundName}>{x.fundName}</abbr>
                        </Box>
                        {tickerList.includes(x.ticker.toUpperCase()) && (
                          <Typography color="error">
                            Already in List!
                          </Typography>
                        )}
                      </Box>
                    ) : null
                  )}
                {!searchResults.some((x) => x.ticker === tickerInput) && (
                  <Box
                    key={`unk-${tickerInput}`}
                    className={classes.searchResultRow}
                    p={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => {
                      if (!tickerList.includes(tickerInput.toUpperCase())) {
                        appendTickerList(tickerInput);
                        searchToggle(false);
                        handleTicker({ target: { value: '' } });
                      }
                    }}
                  >
                    <Box mr={1}>
                      <Typography variant="h6">{tickerInput}</Typography>
                    </Box>
                    <Box
                      style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {tickerList.includes(tickerInput.toUpperCase()) ? (
                        <Typography color="error">Already in List!</Typography>
                      ) : (
                        <Typography>{t('NoneHelper')}</Typography>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Paper>
          </Box>
        </Box>
      </ClickAwayListener>
    </Box>
    {tickerList &&
      tickerList.map((x, idx) => (
        <Box
          key={x}
          alignItems="center"
          display="flex"
          py={0.5}
          borderBottom="1pt solid"
        >
          <Box
            onClick={() => removeIdx(idx)}
            style={{ cursor: 'pointer' }}
            mr={2}
          >
            <DeleteForeverOutlined color="error" />
          </Box>
          <Box style={{ textTransform: 'uppercase' }}>{x}</Box>
        </Box>
      ))}
    <Box mt={2}>
      <Button
        onClick={() => {
          fundParse();
          setTab('CONFIRM');
        }}
      >
        {t('Next')}
      </Button>
    </Box>
  </Box>
);

SearchView.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  setTab: PropTypes.func.isRequired,
  tickerList: PropTypes.array.isRequired,
  handleTicker: PropTypes.func.isRequired,
  tickerInput: PropTypes.string.isRequired,
  appendTickerList: PropTypes.func.isRequired,
  removeIdx: PropTypes.func.isRequired,
  fundParse: PropTypes.func.isRequired,

  searchResults: PropTypes.array.isRequired,
  searchVisible: PropTypes.bool.isRequired,
  searchToggle: PropTypes.func.isRequired,
};

export default SearchView;
