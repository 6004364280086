import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import _ from 'lodash';
import Base from '../Base';

const theme = responsiveFontSizes(
  createTheme(
    _.merge(_.cloneDeep(Base), {
      palette: {
        type: 'dark',
        common: {
          black: '#323232',
          white: '#FFFFFF',
        },
        primary: {
          main: '#FFFFFF',
        },
        secondary: {
          main: '#48A54C',
        },
        error: {
          main: '#EE4343',
        },
        background: {
          default: '#0E437F',
        },
      },
      overrides: {
        MuiLink: {
          root: {
            fontWeight: 'bold',
          },
        },
      },
    })
  )
);

export default theme;
