import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { HubblePage, ListRowView, TopNav } from '../../../components';

const Styles = (theme) => ({
  filterSelect: {
    width: 300,
  },
  errorIcon: {
    margin: theme.spacing(0, 1, 0, 0),
  },
});

const formatTimeString = (timeInQueue) => {
  if (timeInQueue < 60) {
    return `${timeInQueue}s`;
  }
  if (timeInQueue < 3600) {
    return `${Math.floor(timeInQueue / 60)}m ${timeInQueue % 60}s`;
  }
  return `${Math.floor(timeInQueue / 3600)}h ${Math.floor(
    (timeInQueue % 3600) / 60
  )}m ${timeInQueue % 60}s`;
};

const QueueView = ({
  classes,
  errors,
  filterBy,
  updateFilter,
  queueMembers,
  t,
  times,
  width,
}) => (
  <>
    <TopNav />
    <HubblePage>
      <Typography variant="h4">{t('Heading')}</Typography>
      {errors.interrupted && (
        <Box mt={2}>
          <Card>
            <Box p={2} display="flex" alignItems="center">
              <ErrorOutlineIcon color="error" className={classes.errorIcon} />
              <Typography variant="subtitle1" color="error">
                {t('ErrorInterrupted')}
              </Typography>
            </Box>
          </Card>
        </Box>
      )}
      <Box mt={2}>
        <Card>
          <Box
            py={2}
            pl={4}
            pr={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">{t('Subheading')}</Typography>
            <TextField
              select
              fullWidth={isWidthDown('md', width)}
              label="Filter"
              value={filterBy}
              className={classes.filterSelect}
              onChange={updateFilter}
              margin="none"
            >
              {t('Filters', { returnObjects: true }).map((x) => (
                <MenuItem key={x.Value} value={x.Value}>
                  {x.Name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            px={3}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {t('ListHeadings', { returnObjects: true }).map((x) => (
              <Box minWidth="120pt" flex="1" key={x}>
                <Typography variant="caption" color="textSecondary">
                  {x}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box px={2} pb={2}>
            {queueMembers.length > 0 ? (
              queueMembers.map((x) => {
                let color = 'secondary';
                if (x.timeInQueue > times.alert) {
                  color = 'error';
                } else if (x.timeInQueue > times.warn) {
                  color = 'warning';
                }
                return (
                  <ListRowView
                    key={x.id}
                    columns={[
                      x.id,
                      x.name,
                      x.distributor,
                      formatTimeString(x.timeInQueue),
                      x.reason,
                    ]}
                    paletteColor={color}
                    onClick={x.onClick}
                    boxProps={{}}
                  />
                );
              })
            ) : (
              <Box pt={4} pb={2}>
                <Typography variant="h6" align="center" color="secondary">
                  No one in queue. Good job!
                </Typography>
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </HubblePage>
  </>
);

QueueView.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.shape({
    interrupted: PropTypes.bool,
  }).isRequired,
  filterBy: PropTypes.string.isRequired,
  queueMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      distributor: PropTypes.string,
      timeInQueue: PropTypes.number,
      reason: PropTypes.oneOf(['Trade', 'Fund Lineup']),
    })
  ).isRequired,
  updateFilter: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  times: PropTypes.shape({
    warn: PropTypes.number,
    alert: PropTypes.number,
  }),
  width: PropTypes.string.isRequired,
};

QueueView.defaultProps = {
  times: {
    warn: 60,
    alert: 300,
  },
};

export default withStyles(Styles)(withWidth()(QueueView));
