import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Typography,
  withStyles,
  TextField,
  MenuItem,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Icon,
} from '@material-ui/core';

const Styles = () => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
});

const SecurityQuestions = ({ directDetails, NoQText, Placeholder }) => {
  if (!directDetails) {
    return <Typography>{Placeholder}</Typography>;
  }

  if (directDetails.securityQuestions.length === 0) {
    return <Typography>{NoQText}</Typography>;
  }

  return directDetails.securityQuestions.map((q) => (
    <Typography paragraph>{`${q.question}: ${q.answer}`}</Typography>
  ));
};

const directDetailsView = ({
  classes,
  yodleeLink,
  distributorAccountId,
  disableSave,
  loadDirectDetails,
  mfaType,
  acctLinkState,
  changeLinkState,
  changeMfaType,
  saveLoginStatusAndMFAType,
  t,
}) => (
  <Card className={classes.card}>
    <Box px={2} pt={2} flexGrow={1}>
      <Typography variant="h6" paragraph>
        {t('DadHeading')}
      </Typography>
      <Box
        mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="caption">{t('DadUser')}</Typography>
        <Typography>
          {yodleeLink ? yodleeLink.username : t('PlaceholderStars')}
        </Typography>
      </Box>
      <Box
        mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="caption">{t('DadPass')}</Typography>
        <Typography>
          {yodleeLink ? yodleeLink.password : t('PlaceholderStars')}
        </Typography>
      </Box>
      <Box
        mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="caption">{t('DadAcct')}</Typography>
        <Typography>{distributorAccountId ?? t('PlaceholderStars')}</Typography>
      </Box>
      <Typography variant="caption">{t('DadQ')}</Typography>
      <SecurityQuestions
        directDetails={yodleeLink}
        NoQText={t('DadNoQ')}
        Placeholder={t('PlaceholderStars')}
      />
      {yodleeLink != null && (
        <Box my={2} px={1} pb={1} style={{ border: 'thin solid black' }}>
          <TextField
            select
            label={t('MFAType')}
            disabled={yodleeLink == null}
            value={mfaType}
            onChange={changeMfaType}
            fullWidth
          >
            {t('MFATypes', { returnObjects: true }).map((x) => (
              <MenuItem value={x.Value} key={x.Value}>
                {x.Name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label={t('AccountLinkStatus')}
            disabled={yodleeLink == null}
            value={acctLinkState}
            onChange={changeLinkState}
            fullWidth
          >
            {t('AccountLinkStatuses', { returnObjects: true }).map((x) => (
              <MenuItem value={x.Value} key={x.Value}>
                {x.Name}
              </MenuItem>
            ))}
          </TextField>
          <Box mt={2}>
            <Button
              onClick={saveLoginStatusAndMFAType}
              disabled={disableSave}
              fullWidth
            >
              {t('Save')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
    {yodleeLink?.rawLoginFormData && (
      <Box m={1}>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>}>
            {t('FullLogin')}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box width="100%">
              {JSON.parse(yodleeLink.rawLoginFormData).map((x) => (
                <Box
                  mb={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  key={`${x.Name}${x.Value}`}
                >
                  <Typography variant="caption">{x.Name}</Typography>
                  <Typography>{x.Value}</Typography>
                </Box>
              ))}
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Box>
    )}
    <Box px={2} py={1}>
      <Button
        onClick={loadDirectDetails}
        fullWidth
        disabled={yodleeLink != null}
      >
        {t('DadAction')}
      </Button>
    </Box>
    <Box px={2} pb={1}>
      <Button
        onClick={() => {
          if (yodleeLink) {
            window.open(yodleeLink.provider.loginUrl, '_blank');
          }
        }}
        variant="outlined"
        disabled={yodleeLink == null}
        fullWidth
      >
        {t('DadExternal')}
      </Button>
    </Box>
    {yodleeLink?.baseUrl && (
      <Box px={2} pb={1}>
        <Button
          onClick={() => {
            if (yodleeLink) {
              window.open(yodleeLink.provider.baseUrl, '_blank');
            }
          }}
          variant="outlined"
          disabled={yodleeLink == null}
          fullWidth
        >
          {t('DadExternalAlternate')}
        </Button>
      </Box>
    )}
  </Card>
);

directDetailsView.propTypes = {
  classes: PropTypes.object.isRequired,
  loadDirectDetails: PropTypes.func.isRequired,
  disableSave: PropTypes.bool.isRequired,
  yodleeLink: PropTypes.shape({
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    distributorAccountId: PropTypes.string.isRequired,
    rawLoginFormData: PropTypes.string,
    provider: PropTypes.shape({
      loginUrl: PropTypes.string.isRequired,
      baseUrl: PropTypes.string,
    }),
  }),
  mfaType: PropTypes.string.isRequired,
  acctLinkState: PropTypes.number.isRequired,
  changeLinkState: PropTypes.func.isRequired,
  changeMfaType: PropTypes.func.isRequired,
  saveLoginStatusAndMFAType: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  distributorAccountId: PropTypes.string,
};

directDetailsView.defaultProps = {
  yodleeLink: undefined,
  distributorAccountId: undefined,
};

export default withStyles(Styles)(directDetailsView);
