import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';

const ChartImager = ({ ChartComponent, callback, data, options, show }) => {
  const chartRef = useRef();
  const theme = useTheme();
  useEffect(() => {
    if (chartRef.current.chartInstance) {
      callback(chartRef.current.chartInstance.toBase64Image());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef]);
  const LEGEND = {
    position: 'bottom',
    fullWidth: 'true',
    labels: {
      boxWidth: 10,
      fontSize: 14,
      fontColor: theme.palette.common.black,
      fontStyle: 'bold',
      fontFamily: theme.typography.fontFamily,
      padding: 10,
    },
    onClick: () => {},
  };
  return (
    <>
      <div
        style={
          !show && {
            visibility: 'hidden',
            position: 'absolute',
            zIndex: '-100',
            width: '500pt',
          }
        }
      >
        <ChartComponent
          ref={chartRef}
          data={data}
          options={options}
          legend={LEGEND}
        />
      </div>
    </>
  );
};

ChartImager.propTypes = {
  ChartComponent: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  show: PropTypes.bool,
};
ChartImager.defaultProps = {
  show: false,
};

export default ChartImager;
