import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';
import ChartImager from '../../../components/ChartImager';
import { mFormat } from '../../../utils/Money';

const ManagedAssetsChart = ({ report, setB64Img }) => {
  const theme = useTheme();
  const COLORS = [
    theme.palette.primary.main,
    theme.palette.error.light,
    theme.palette.error.main,
    theme.palette.grey[500],
  ];

  const CONFIG = {
    options: {
      scales: {
        yAxes: [
          {
            display: false,
            ticks: {
              suggestedMin: 0,
            },
          },
        ],
      },
      animation: {
        duration: 0,
      },
      title: {
        display: true,
        text: `Total: ${mFormat(report.manageableAssets)}`,
        position: 'bottom',
        fontSize: 16,
      },
    },
  };

  const ds = {
    datasets: [
      {
        data: [
          report.manageableAssets - report.managedAssets,
          report.managedAssets,
        ],
        backgroundColor: COLORS,
      },
    ],
    labels: ['Manageable Assets', 'Managed Assets'],
  };
  return (
    <ChartImager
      callback={setB64Img}
      ChartComponent={Doughnut}
      data={ds}
      options={CONFIG.options}
    />
  );
};

ManagedAssetsChart.propTypes = {
  report: PropTypes.object.isRequired,
  setB64Img: PropTypes.func.isRequired,
};

export default ManagedAssetsChart;
