import { useState } from 'react';
import createContainer from 'constate';
import Session from '../Session';
import Config from '../../config';
import {
  stateUpdaterFactory,
  dispatcherFactory,
  dataObjBuilder,
} from '../../utils/DataUtil';
import { HUBBLE_SESSION_AUTH } from '../Login';

const defaultHeaders = () => {
  const { token } = Session.get(HUBBLE_SESSION_AUTH) || {};
  return { Authorization: `Bearer ${token}` };
};

const fetchPlanSponsorReport = async (planSponsorId) =>
  fetch(`${Config.pronvest.api.url}Reports/PlanSponsor/${planSponsorId}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...defaultHeaders(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

const Reports = () => {
  const [planSponsorReport, setPlanSponsorReport] = useState();
  const [planSponsorReportState, setPlanSponsorReportState] = useState('EMPTY');
  const psReportUpdater = stateUpdaterFactory(
    setPlanSponsorReport,
    setPlanSponsorReportState
  );
  const psReportDispatcher = (psid) =>
    dispatcherFactory(planSponsorReportState, psReportUpdater, () =>
      fetchPlanSponsorReport(psid)
    )();

  return {
    planSponsor: dataObjBuilder(
      planSponsorReport,
      planSponsorReportState,
      (pp) => psReportDispatcher(pp)
    ),
  };
};
const [Provider, useReports] = createContainer(Reports);

export { useReports };

export default Provider;
