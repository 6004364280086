import React, { useContext } from 'react';
import { AppContext } from '../../modules/AppContext';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Toast = () => {
  const { toastMessage, toastType, toastIsVisible, setToastIsVisible } =
    useContext(AppContext);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastIsVisible(false);
  };

  // const types = ['error', 'warning', 'info', 'success'];

  return (
    <Snackbar
      open={toastIsVisible}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={toastType}>
        {toastMessage}
      </Alert>
    </Snackbar>
  );
};
