import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useReports } from '../../modules/Reports';
import PlanSponsorReportView from './PlanSponsorReport-view';

const PlanSponsorReportContainer = () => {
  const match = useRouteMatch();
  const reports = useReports();
  const planSponsorReport = reports.planSponsor;

  useEffect(() => {
    planSponsorReport.dispatch(match.params.planSponsorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.planSponsorId]);

  const [ManagedParticipants, setManagedParticipantChart] = useState(null);
  const [ManagedAssets, setManagedAssetsChart] = useState(null);
  const [ManagedByDate, setManagedByDate] = useState(null);
  const [ManagedAssetsByDate, setManagedAssetsByDate] = useState(null);
  const [StrategyBreakdown, setStrategyBreakdown] = useState(null);
  const [StrategySummary, setStrategySummary] = useState(null);
  const [InteractionSummary, setInteractionSummary] = useState(null);
  const [GapHired, setGapHired] = useState(null);

  const rows =
    planSponsorReport.data &&
    planSponsorReport.data.interactions.length > 0 &&
    Object.keys(planSponsorReport.data.interactions[0]).map((x) => (
      <th key={x}>{x}</th>
    ));
  return (
    planSponsorReport.state === 'LOADED' &&
    planSponsorReport.data != null && (
      <PlanSponsorReportView
        heading="Plan Sponsor Stats"
        report={planSponsorReport.data}
        status={planSponsorReport.state}
        chartImages={{
          ManagedAssets,
          ManagedParticipants,
          ManagedByDate,
          ManagedAssetsByDate,
          StrategyBreakdown,
          StrategySummary,
          InteractionSummary,
          GapHired,
        }}
        data={{
          rows,
        }}
        setter={{
          setManagedAssetsChart,
          setManagedParticipantChart,
          setManagedByDate,
          setManagedAssetsByDate,
          setStrategyBreakdown,
          setStrategySummary,
          setInteractionSummary,
          setGapHired,
        }}
      />
    )
  );
};

export default PlanSponsorReportContainer;
