import React, { useState, useContext } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import {
  Typography,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';

import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { Search, Toast } from '../../components/';
import { OddityReports } from '../OddityReports/OddityReports';
import { SpeakeasyTrades } from '../SpeakeasyTrades/SpeakeasyTrades';

import { AppContext } from '../../modules/AppContext';

import logo from '../../logos/futureLogo.svg';

import { makeStyles } from '@material-ui/core/styles';

// Break this out into its o

const drawerWidth = 300;
const toolbarHeight = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    background: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: {
    minHeight: toolbarHeight,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  logo: {
    width: 200,
    height: toolbarHeight,
    marginLeft: 20,
  },
}));

export const Dashboard = ({ history }) => {
  const { searchToken, setSearchToken } = useContext(AppContext);

  const handleSearch = (event) => {
    setSearchToken(event.target.value);
  };

  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = React.useState(
    '/trades/oddity-reports'
  );
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const sidebar = [
    {
      title: 'Summaries',
      route: 'summaries',
      icon: 'paper-money',
      pages: [
        {
          title: 'Reports',
          route: 'reports',
        },
        {
          title: 'Trade Queue',
          route: 'trade-queue',
        },
      ],
    },
    {
      title: 'Users',
      route: 'users',
      icon: 'paper-money',
      pages: [
        {
          title: 'Participants',
          route: 'participants',
        },
        {
          title: 'Distributors',
          route: 'distributors',
        },
        {
          title: 'Plan Sponsors',
          route: 'plan-sponsors',
        },
      ],
    },
    {
      title: 'Trades',
      route: 'trades',
      icon: 'paper-money',
      pages: [
        {
          title: 'Oddity Reports',
          route: 'oddity-reports',
        },
        {
          title: 'Speakeasy Trades',
          route: 'speakeasy-trades',
        },
        {
          title: 'Trades',
          route: 'trades',
        },
      ],
    },
    {
      title: 'Direct',
      route: 'direct',
      icon: 'paper-money',
      pages: [
        {
          title: 'Advisors',
          route: 'advisors',
        },
        {
          title: 'Consumers',
          route: 'consumers',
        },
        {
          title: 'Trades',
          route: 'trades',
        },
        {
          title: 'Fees',
          route: 'fees',
        },
      ],
    },
  ];

  return (
    <div className={classes.root}>
      <Toast />
      <AppBar
        position="fixed"
        className={classes.appBar}
        elevation={0}
        color="default"
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" noWrap>
            <Search
              value={searchToken}
              onChange={handleSearch}
              onClear={() => setSearchToken('')}
            />
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <img src={logo} alt="future-capital" className={classes.logo} />
        <List>
          {sidebar.map((section, index) => (
            <React.Fragment key={section.title}>
              <ListItem
                button
                key={section.route}
                selected={selectedIndex === section.route}
                onClick={(event) => handleListItemClick(event, section.route)}
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={section.title} />
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {section.pages.map((page) => (
                    <ListItem
                      key={page.title}
                      button
                      className={classes.nested}
                      onClick={() => {
                        history.push(
                          `/dashboard/${section.route}/${page.route}`
                        );
                      }}
                    >
                      <FiberManualRecordIcon
                        style={{
                          fontSize: '8px',
                          marginLeft: '32px',
                          marginRight: '15px',
                        }}
                      />
                      <ListItemText primary={page.title} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route
            exact
            path="/dashboard/trades/oddity-reports"
            component={OddityReports}
          />
          <Route
            exact
            path="/dashboard/trades/speakeasy-trades"
            component={SpeakeasyTrades}
          />
          <Route path="*" component={() => <div>Coming Soon!</div>} />
          <Redirect to="/dashboard/trades/oddity-reports" />{' '}
          {/* If we get here to the end and have not matched a route, default back to oddity reports */}
        </Switch>
      </main>
    </div>
  );
};
