import React, { useState } from 'react';

import moment from 'moment';
import { Button } from '@material-ui/core';

import { Datatable } from '../../components';

export const TradesInitiated = ({ data, updateStatus, tradesLoading }) => {
  const [showCodes, setShowCodes] = useState([]);

  const toggleShowCode = (code) => {
    setShowCodes((prev) => {
      if (prev.includes(code)) {
        return prev.filter((f) => f !== code);
      } else {
        return [...prev, code];
      }
    });
  };

  const tableConfig = [
    {
      sortable: true,
      heading: 'Status',
      key: 'status',
      formatter: (val) => val,
    },
    {
      sortable: true,
      heading: 'Participant Name',
      key: 'participantName',
    },
    {
      sortable: true,
      heading: 'Participant ID',
      key: 'participantId',
      formatter: (val) => val,
    },
    {
      sortable: true,
      heading: 'Expires In',
      key: 'created',
      formatter: (val) =>
        val
          ? moment(val).isAfter(new Date())
            ? moment(val).add(1, 'days').fromNow()
            : 'expired'
          : '',
    },
    { heading: 'Product', key: 'product', formatter: (val) => val }, // FIXME: how to get product?
    { heading: 'Type', key: 'type', formatter: (val) => val },
    {
      heading: 'View Code',
      key: 'code',
      formatter: (val, row) => (
        <Button
          disabled={!val}
          size="small"
          color="secondary"
          onClick={() => toggleShowCode(row.id)}
        >
          {showCodes.includes(row.id) ? val : 'View Code'}
        </Button>
      ),
    },
    {
      heading: 'Complete Trade',
      key: 'id',
      formatter: (val, row) => (
        <Button
          disabled={row.status === 'completed'}
          size="small"
          color="primary"
          onClick={() => {
            updateStatus(val, 'completed');
          }}
        >
          Complete
        </Button>
      ),
    },
  ];

  return (
    <Datatable
      loading={tradesLoading}
      tableHeading="Trades Initiated"
      defaultSortColumn="created"
      defaultSortDirection="desc"
      data={data}
      tableConfig={tableConfig}
      paginationLeft={true}
    />
  );
};
