import { useState } from 'react';
import createContainer from 'constate';
import Config from '../../config';
import { fetcher } from '../../utils/DataUtil';
import Session from '../Session';
import { HUBBLE_SESSION_AUTH } from '../Login';

const defaultHeaders = () => {
  const { token } = Session.get(HUBBLE_SESSION_AUTH) || {};
  return { Authorization: `Bearer ${token}` };
};

const fetchRunList = (name) =>
  fetch(`${Config.pronvest.api.url}Hubble/Runs/${name}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...defaultHeaders(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

const fetchRun = (name, runid) =>
  fetch(`${Config.pronvest.api.url}Hubble/Runs/${name}/${runid}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...defaultHeaders(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
const fetchRunActions = (name, runid) =>
  fetch(`${Config.pronvest.api.url}Hubble/Runs/${name}/${runid}/Actions`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...defaultHeaders(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
const fetchRunActionsIO = (name, runid) =>
  fetch(`${Config.pronvest.api.url}Hubble/Runs/${name}/${runid}/Actions/IO`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...defaultHeaders(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
const fetchSpecificRunActionIO = (name, runid, actionName) =>
  fetch(
    `${Config.pronvest.api.url}Hubble/Runs/${name}/${runid}/Action/IO?action=${actionName}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        ...defaultHeaders(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );

const Workflows = () => {
  const [runStore, setRunStore] = useState({});
  const [actionStore, setActionStore] = useState({});
  const [ioStore, setIoStore] = useState({});
  const [runListStore, setRunListStore] = useState({});

  return {
    getRunList: async (name) => {
      if (runListStore[name]) {
        return runListStore[name];
      }
      const f = await fetchRunList(name);
      const r = await fetcher(() => f);
      setRunListStore({ ...runListStore, [name]: await r });
      return r;
    },
    getRun: async (name, runid) => {
      if (runStore[name] && runStore[name][runid]) {
        return runStore[name][runid];
      }
      const f = await fetchRun(name, runid);
      const r = await fetcher(() => f);
      setRunStore({ ...runStore, [name]: { [runid]: r } });
      return r;
    },
    getRunActions: async (name, runid) => {
      if (actionStore[name] && actionStore[name][runid]) {
        return actionStore[name][runid];
      }
      const f = await fetchRunActions(name, runid);
      const r = await fetcher(() => f);
      setActionStore({ ...actionStore, [name]: { [runid]: r } });
      return r;
    },
    getRunActionsIO: async (name, runid) => {
      if (ioStore[name] && ioStore[name][runid]) {
        return ioStore[name][runid];
      }
      const f = await fetchRunActionsIO(name, runid);
      const r = await fetcher(() => f);
      setIoStore({ ...ioStore, [name]: { [runid]: r } });
      return r;
    },
    getSpecificRunActionIO: async (name, runid, actionName) => {
      // if (ioStore[name] && ioStore[name][runid]) {
      //   return ioStore[name][runid];
      // }
      const f = await fetchSpecificRunActionIO(name, runid, actionName);
      const r = await fetcher(() => f);
      // setIoStore({ ...ioStore, [name]: { [runid]: r } });
      return r;
    },
    runStore,
    actionStore,
    ioStore,
  };
};

const [Provider, useWorkflowRuns] = createContainer(Workflows);

export { useWorkflowRuns };
export default Provider;
