import { useState } from 'react';
import createContainer from 'constate';
import Config from '../../config';
import {
  stateUpdaterFactory,
  dispatcherFactory,
  dataObjBuilder,
} from '../../utils/DataUtil';
import Session from '../Session';
import { HUBBLE_SESSION_AUTH } from '../Login';

const defaultHeaders = () => {
  const { token } = Session.get(HUBBLE_SESSION_AUTH) || {};
  return { Authorization: `Bearer ${token}` };
};

const fetchWorkflows = async () =>
  fetch(`${Config.pronvest.api.url}Hubble/Feed`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...defaultHeaders(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

const Workflows = () => {
  const [allWorkflows, setAllWorkflows] = useState([]);
  const [allWorkflowsState, setAllWorkflowsState] = useState('EMPTY');
  const workflowUpdater = stateUpdaterFactory(
    setAllWorkflows,
    setAllWorkflowsState
  );
  const workflowDispatcher = dispatcherFactory(
    allWorkflowsState,
    workflowUpdater,
    fetchWorkflows
  );

  return {
    workflowsList: dataObjBuilder(
      allWorkflows,
      allWorkflowsState,
      workflowDispatcher
    ),
  };
};

const [Provider, useWorkflows] = createContainer(Workflows);

export { useWorkflows };

export default Provider;
