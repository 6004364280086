import React, { useCallback, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMFA } from '../../../modules/admin';
import { useSignalR } from '../../../modules/SignalR';

import { LoadingView } from '../../../components';
import View from './Queue-view';

const QueueContainer = ({ history }) => {
  const mfa = useMFA();
  const signalR = useSignalR();
  const { t } = useTranslation('App.MFA.Queue');
  const [filterBy, setFilterBy] = useState('all');
  const [errors, setErrors] = useState({});
  const [queueMembers, setQueueMembers] = useState();

  const assistParticipant = useCallback(
    (connectionId, guid, queueReason) => async () => {
      history.push(`/mfa/assist/${guid}`, queueReason);
      const { conversationId, opsMemberName } =
        (await mfa.assistParticipant(connectionId, guid)) || {};
      if (conversationId == null) {
        throw new Error(`Unable to assist participant ${guid}`);
      }
      signalR.joinConversation({
        id: conversationId,
        opsMemberName,
      });
    },
    [history, mfa, signalR]
  );

  useEffect(() => {
    const initialize = async () => {
      const connectionId = await signalR.connect();
      if (connectionId == null) {
        throw new Error('Something went wrong intiializing signalR!');
      }
      if (await mfa.register(connectionId)) {
        const members = await mfa.getQueueMembers();
        if (members != null) {
          setQueueMembers(
            members
              .map((x) => ({
                guid: x.guid,
                name: `${x.firstName} ${x.lastName}`,
                distributor: x.distributorName,
                id: x.participantId,
                reason: x.queueType,
                timeInQueue: x.secondsWaiting,
                onClick: assistParticipant(connectionId, x.guid, x.queueType),
              }))
              .sort((x, y) => y.timeInQueue - x.timeInQueue)
          );
        } else {
          throw new Error('Could not get list of queue members.');
        }
      } else {
        throw new Error('Could not register as an ops member with signalR.');
      }
    };

    if (queueMembers == null) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signalR.state === 'add') {
      const connectionId = signalR.getConnectionId();
      const {
        guid,
        firstName,
        lastName,
        distributorName,
        participantId,
        queueType,
        secondsWaiting,
      } = signalR.data || {};
      setQueueMembers((qm) =>
        [
          ...qm,
          {
            guid,
            name: `${firstName} ${lastName}`,
            distributor: distributorName,
            id: participantId,
            reason: queueType,
            timeInQueue: secondsWaiting,
            onClick: assistParticipant(connectionId, guid, queueType),
          },
        ].sort((x, y) => y.timeInQueue - x.timeInQueue)
      );
      signalR.reset();
    } else if (signalR.state === 'remove') {
      const guid = signalR.data;
      setQueueMembers((qm) => qm.filter((x) => x.guid !== guid));
      signalR.reset();
    } else if (signalR.state === 'interrupted') {
      setErrors({ interrupted: true });
      signalR.reset();
    }
  }, [assistParticipant, signalR]);

  useEffect(() => {
    if (queueMembers != null) {
      const timer = setTimeout(() => tick(), 1000);
      return () => clearTimeout(timer);
    }
    return () => {};
  });

  const tick = () => {
    setQueueMembers((q) =>
      q.map((x) => ({
        ...x,
        timeInQueue: x.timeInQueue + 1,
      }))
    );
  };

  const updateFilter = (event) => {
    setFilterBy(event.target.value);
  };

  if (queueMembers == null) {
    return <LoadingView message={t('Loading')} />;
  }

  return (
    <View
      errors={errors}
      filterBy={filterBy}
      updateFilter={updateFilter}
      queueMembers={queueMembers.filter(
        (x) => filterBy === 'all' || x.reason === filterBy
      )}
      t={t}
    />
  );
};

QueueContainer.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(QueueContainer);
