import LogRocket from 'logrocket';
import Session from '../Session';
import Config from '../../config';

const HUBBLE_SESSION_AUTH = 'Hubble_Auth';

const LOGIN_STATES = {
  pending: 'LOGGINGIN',
  loggedin: 'LOGGEDIN',
};

const login = () => {
  Session.set(HUBBLE_SESSION_AUTH, {
    redirect: window.location.pathname,
    loginStatus: LOGIN_STATES.pending,
  });
  window.location = `${Config.pronvest.api.url}AzureAD/OauthLogin/Hubble`;
};

const healthCheck = async () => {
  const { token } = Session.get(HUBBLE_SESSION_AUTH) || {};
  const r = await fetch(`${Config.pronvest.api.url}AzureAD/HealthCheck`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!r.ok) {
    login();
  } else {
    const { name, email } = await r.json();
    LogRocket.identify(email, { name });
  }
};

export { healthCheck, login, LOGIN_STATES, HUBBLE_SESSION_AUTH };
