import React from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import ListRowView from '../../components/ListRow/ListRow-view';

const Review = ({
  t,
  parsedFunds,
  assetClasses,
  openPalms,

  // Fund Objects to Submit
  fluFuncs,
  conflictFunds,
  addFunds,
  removeFunds,
  createFunds,
  confirm,
  confirmStatus,
}) => (
  <>
    {confirmStatus === 'init' && (
      <>
        <Box p={2}>
          {parsedFunds && addFunds && (
            <Box mb={2}>
              <Typography variant="h6">{`${t('Additions')}: ${
                Object.keys(parsedFunds.add).length
              }`}</Typography>
              <Box
                pl={7}
                pr={3}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {t('ListHeadings', { returnObjects: true }).map((c) => (
                  <Box minWidth="120pt" flex="1" key={c}>
                    <Typography variant="caption" color="textSecondary">
                      {c}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {Object.keys(parsedFunds.add).length === 0 && (
                <Typography>{t('None')}</Typography>
              )}
              {Object.values(parsedFunds.add).map((x) => {
                if (x.length > 0) {
                  return (
                    <ListRowView
                      key={x[0].ticker}
                      checkbox
                      selected={addFunds[x[0].ticker].confirmed || false}
                      onCheck={() => {
                        fluFuncs.setAddFunds({
                          ...addFunds,
                          [x[0].ticker]: {
                            ...addFunds[x[0].ticker],
                            confirmed: !addFunds[x[0].ticker].confirmed,
                          },
                        });
                      }}
                      paletteColor="secondary"
                      columns={[
                        t('Ok'),
                        x[0].ticker,
                        x[0].fundName,
                        x[0].cusip,
                        assetClasses[x[0].assetClassId - 1],
                      ]}
                    />
                  );
                }
                return null;
              })}
            </Box>
          )}
          {parsedFunds && conflictFunds && (
            <Box mb={2}>
              <Typography variant="h6">{`${t('Conflicts')}: ${
                Object.keys(parsedFunds.conflict).length
              }`}</Typography>
              <Box
                pl={7}
                pr={3}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {t('ListHeadings', { returnObjects: true }).map((c) => (
                  <Box minWidth="120pt" flex="1" key={c}>
                    <Typography variant="caption" color="textSecondary">
                      {c}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {Object.keys(parsedFunds.conflict).length === 0 && (
                <Typography>{t('None')}</Typography>
              )}
              {Object.values(parsedFunds.conflict).map((x) => {
                if (x.length > 0) {
                  return (
                    <ListRowView
                      key={x[0].ticker}
                      checkbox
                      selected={conflictFunds[x[0].ticker].confirmed}
                      onCheck={() => {
                        fluFuncs.setConflictFunds({
                          ...conflictFunds,
                          [x[0].ticker]: {
                            ...conflictFunds[x[0].ticker],
                            confirmed: !conflictFunds[x[0].ticker].confirmed,
                          },
                        });
                      }}
                      paletteColor="warning"
                      hasNodes
                      nodes={[
                        t('ConfAttn'),
                        x[0].ticker,
                        <Select
                          onChange={(e) => {
                            fluFuncs.setConflictFunds({
                              ...conflictFunds,
                              [x[0].ticker]: {
                                ...conflictFunds[x[0].ticker],
                                fundId: e.target.value,
                              },
                            });
                          }}
                          fullWidth
                        >
                          {x.map((f) => (
                            <MenuItem key={f.fundId} value={f.fundId}>
                              {f.fundName}
                            </MenuItem>
                          ))}
                        </Select>,
                        x[0].cusip,
                        assetClasses[x[0].assetClassId - 1],
                      ]}
                    />
                  );
                }
                return null;
              })}
            </Box>
          )}
          {parsedFunds && (
            <Box mb={2}>
              <Typography variant="h6">{`${t('Unchanged')}: ${
                Object.keys(parsedFunds.unchanged).length
              }`}</Typography>
              <Box
                pl={7}
                pr={3}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {t('ListHeadings', { returnObjects: true }).map((c) => (
                  <Box minWidth="120pt" flex="1" key={c}>
                    <Typography variant="caption" color="textSecondary">
                      {c}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {Object.keys(parsedFunds.unchanged).length === 0 && (
                <Typography>{t('None')}</Typography>
              )}
              {Object.values(parsedFunds.unchanged).map((x) => {
                if (x.length > 0) {
                  return (
                    <ListRowView
                      key={x[0].ticker}
                      paletteColor="grey"
                      columns={[
                        t('Ok'),
                        x[0].ticker,
                        x[0].fundName,
                        x[0].cusip,
                        assetClasses[x[0].assetClassId - 1],
                      ]}
                    />
                  );
                }
                return null;
              })}
            </Box>
          )}
          {parsedFunds && removeFunds && (
            <Box mb={2}>
              <Typography variant="h6">{`${t('Removals')}: ${
                Object.keys(parsedFunds.remove).length
              }`}</Typography>
              <Box
                pl={7}
                pr={3}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {t('ListHeadings', { returnObjects: true }).map((c) => (
                  <Box minWidth="120pt" flex="1" key={c}>
                    <Typography variant="caption" color="textSecondary">
                      {c}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {Object.keys(parsedFunds.remove).length === 0 && (
                <Typography>{t('None')}</Typography>
              )}
              {Object.values(parsedFunds.remove).map((x) => {
                if (x.length > 0) {
                  return (
                    <ListRowView
                      key={x[0].ticker}
                      checkbox
                      selected={removeFunds[x[0].ticker].confirmed}
                      onCheck={() => {
                        fluFuncs.setRemoveFunds({
                          ...removeFunds,
                          [x[0].ticker]: {
                            ...removeFunds[x[0].ticker],
                            confirmed: !removeFunds[x[0].ticker].confirmed,
                          },
                        });
                      }}
                      paletteColor="error"
                      columns={[
                        t('Ok'),
                        x[0].ticker,
                        x[0].fundName,
                        x[0].cusip,
                        assetClasses[x[0].assetClassId - 1],
                      ]}
                    />
                  );
                }
                return null;
              })}
            </Box>
          )}
          {parsedFunds && createFunds && (
            <Box mb={2}>
              <Typography variant="h6">{`${t('Created')}: ${
                Object.keys(parsedFunds.create).length
              }`}</Typography>
              <Box
                pl={7}
                pr={3}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {t('ListHeadings', { returnObjects: true }).map((c) => (
                  <Box minWidth="120pt" flex="1" key={c}>
                    <Typography variant="caption" color="textSecondary">
                      {c}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {Object.keys(parsedFunds.create).length === 0 && (
                <Typography>{t('None')}</Typography>
              )}
              {Object.keys(parsedFunds.create).map((x) => {
                if (x.length > 0) {
                  return (
                    <ListRowView
                      key={x}
                      checkbox
                      selected={createFunds[x].confirmed}
                      onCheck={() => {
                        fluFuncs.setCreateFunds({
                          ...createFunds,
                          [x]: {
                            ...createFunds[x],
                            confirmed: !createFunds[x].confirmed,
                          },
                        });
                      }}
                      paletteColor="primary"
                      hasNodes
                      nodes={[
                        <Typography>{t('NewAttn')}</Typography>,
                        <Typography>{x}</Typography>,
                        <TextField
                          onChange={(e) => {
                            fluFuncs.setCreateFunds({
                              ...createFunds,
                              [x]: {
                                ...createFunds[x],
                                fundName: e.target.value,
                              },
                            });
                          }}
                          label={t('FundName')}
                          value={createFunds[x].fundName}
                        />,
                        <TextField
                          onChange={(e) => {
                            fluFuncs.setCreateFunds({
                              ...createFunds,
                              [x]: {
                                ...createFunds[x],
                                cusip: e.target.value,
                              },
                            });
                          }}
                          label={t('CUSIP')}
                        />,
                        <TextField
                          onChange={(e) => {
                            fluFuncs.setCreateFunds({
                              ...createFunds,
                              [x]: {
                                ...createFunds[x],
                                assetClassId: e.target.value,
                              },
                            });
                          }}
                          select
                          label={t('AssetClass')}
                          value={createFunds[x].assetClassId || ''}
                        >
                          {assetClasses.map((c, i) => (
                            <MenuItem key={c} value={i + 1}>
                              {c}
                            </MenuItem>
                          ))}
                        </TextField>,
                      ]}
                    />
                  );
                }
                return null;
              })}
            </Box>
          )}
        </Box>
        {(addFunds, removeFunds, conflictFunds, parsedFunds, createFunds) && (
          <Box m={2} p={2} border="1pt solid">
            <Typography>
              {`${t('FundstoAdd')}: ${
                Object.values(addFunds).filter((x) => x.confirmed).length +
                Object.values(conflictFunds).filter((x) => x.confirmed).length
              }`}
            </Typography>
            <Typography>
              {`${t('FundstoRemove')}: ${
                Object.values(removeFunds).filter((x) => x.confirmed).length
              }`}
            </Typography>
            <Typography>
              {`${t('FundstoCreate')}: ${
                Object.values(createFunds).filter((x) => x.confirmed).length
              }`}
            </Typography>
            <Typography>
              {`${t('FundsUnchanged')}: ${
                Object.keys(parsedFunds.unchanged).length
              }`}
            </Typography>
            <Box mt={2}>
              <Button onClick={confirm}>{t('ConfirmAction')}</Button>
            </Box>
          </Box>
        )}
        {!parsedFunds && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={3}
          >
            <CircularProgress variant="indeterminate" />
          </Box>
        )}
      </>
    )}
    {confirmStatus === 'loading' && (
      <Box
        display="flex"
        py={3}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography align="center" variant="h5">
          {t('WaitMessage')}
        </Typography>
        <Box my={2}>
          <CircularProgress variant="indeterminate" />
        </Box>
      </Box>
    )}
    {confirmStatus === 'done' && (
      <Box
        display="flex"
        py={3}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box>
          <Typography align="center" variant="h5" paragraph>
            {t('SuccessMessage')}
          </Typography>
          <Typography align="center">{t('SuccessBody')}</Typography>
        </Box>
        <Box my={2}>
          <CheckCircleIcon color="secondary" fontSize="large" />
        </Box>
        <Button onClick={openPalms}>{t('OpenPalms')}</Button>
      </Box>
    )}
    {confirmStatus === 'err' && (
      <Box
        display="flex"
        py={3}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography align="center" variant="h5">
          {t('ErrorMessage')}
        </Typography>
        <Typography align="center" variant="body1">
          {t('ErrorAction')}
        </Typography>
        <ErrorOutlineRoundedIcon color="error" fontSize="large" />
      </Box>
    )}
  </>
);

Review.propTypes = {
  t: PropTypes.func.isRequired,
  parsedFunds: PropTypes.object.isRequired,
  assetClasses: PropTypes.arrayOf(PropTypes.string).isRequired,

  fluFuncs: PropTypes.object.isRequired,
  conflictFunds: PropTypes.object.isRequired,
  addFunds: PropTypes.object.isRequired,
  removeFunds: PropTypes.object.isRequired,
  createFunds: PropTypes.object.isRequired,

  confirm: PropTypes.func.isRequired,
  confirmStatus: PropTypes.string.isRequired,

  openPalms: PropTypes.func.isRequired,
};

export default Review;
