import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';
import ChartImager from '../../../components/ChartImager';

const StrategySummaryChart = ({ report, setB64Img }) => {
  const theme = useTheme();
  const COLORS = [
    theme.palette.grey[500],
    theme.palette.secondary.main,
    theme.palette.primary.main,
    theme.palette.error.light,
    theme.palette.error.main,
  ];

  const CONFIG = {
    options: {
      scales: {
        yAxes: [
          {
            display: false,
            ticks: {
              suggestedMin: 0,
            },
          },
        ],
      },
      animation: {
        duration: 0,
      },
      title: {
        display: true,
        text: 'Strategies Summary',
        position: 'bottom',
        fontSize: 16,
      },
    },
  };

  const getCount = (strategyName) =>
    report.strategies
      .filter((x) => x.strategy.startsWith(strategyName))
      .map((x) => x.count)
      .reduce((a, c) => a + c, 0);

  const ds = {
    datasets: [
      {
        data: [
          getCount('Conservative'),
          getCount('Moderate Conservative'),
          getCount('Moderate'),
          getCount('Moderate Aggressive'),
          getCount('Aggressive'),
        ],
        backgroundColor: COLORS,
      },
    ],
    labels: [
      'Conservative',
      'Moderate Conservative',
      'Moderate',
      'Moderate Aggressive',
      'Aggressive',
    ],
  };
  return (
    <ChartImager
      callback={setB64Img}
      ChartComponent={Doughnut}
      data={ds}
      options={CONFIG.options}
    />
  );
};

StrategySummaryChart.propTypes = {
  report: PropTypes.object.isRequired,
  setB64Img: PropTypes.func.isRequired,
};

export default StrategySummaryChart;
