import { useState } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import constate from 'constate';
import Config from '../../config';

const CONNECTION_STATES = {
  connected: 'Connected',
  connecting: 'Connecting',
  disconnected: 'Disconnected',
  disconnecting: 'Disconnecting',
  reconnecting: 'Reconnecting',
};

const SignalR = () => {
  const [data, setData] = useState();
  const [state, setState] = useState();
  const [conversationId, setConversationId] = useState();
  const [name, setName] = useState();
  const [connection, setConnection] = useState();

  const startup = () => {
    const conn = new HubConnectionBuilder()
      .withUrl(Config.pronvest.signalR.url)
      .configureLogging(LogLevel.Error)
      .build();

    conn.on('newparticipant', (payload) => {
      setData(payload);
      setState('add');
    });

    conn.on('removeparticipantfromqueue', (payload) => {
      setData(payload);
      setState('remove');
    });

    conn.on('answer', (payload) => {
      setData(payload);
      setState('answer');
    });

    conn.on('code', (payload) => {
      setData(payload);
      setState('code');
    });

    conn.on('didnotreceive', () => {
      setState('didnotreceive');
    });

    conn.on('interrupted', () => {
      setState('interrupted');
    });

    setConnection(conn);
    return conn;
  };

  const sendMessage = (message, payload = {}) => {
    if (connection != null) {
      connection.invoke(
        'sendmessage',
        conversationId,
        message,
        JSON.stringify(payload)
      );
    } else {
      throw new Error(
        'Attempted to send signalR message before connection was setup.'
      );
    }
  };

  const echo = (message, payload = {}) => {
    if (connection != null) {
      connection.invoke('echo', message, JSON.stringify(payload));
    } else {
      throw new Error(
        'Attempted to echo signalR message before connection was setup.'
      );
    }
  };

  const currentlyConnected = () =>
    connection != null && connection.state === CONNECTION_STATES.connected;

  return {
    data,
    state,
    reset: () => {
      setData();
      setState();
    },
    connect: async () => {
      let conn = connection;
      if (conn == null) {
        conn = startup();
      }
      if (conn.state === CONNECTION_STATES.disconnected) {
        await conn.start();
      }
      return conn?.connectionId;
    },
    disconnect: async () => {
      if (currentlyConnected()) {
        await connection.stop();
        setConnection();
      }
    },
    isConnecting: () =>
      connection != null && connection.state === CONNECTION_STATES.connecting,
    isConnected: () => currentlyConnected(),
    getConnectionId: () => connection?.connectionId,
    joinConversation: ({ id, opsMemberName }) => {
      setConversationId(id);
      setName(opsMemberName);
    },
    exitConversation: () => {
      setConversationId();
      setName();
    },
    inConversation: () => (connection != null ? conversationId != null : false),
    assist: (type, queueType, question = undefined) =>
      sendMessage('assist', {
        conversationId,
        opsMemberName: name,
        mfaType: type,
        queueType,
        question,
      }),
    retry: (type, error, question = undefined) =>
      sendMessage('retry', {
        mfaType: type,
        question,
        error,
      }),
    success: () => sendMessage('success'),
    failure: () => sendMessage('failedlogin'),
    echo,
  };
};

const [SignalRProvider, useSignalR] = constate(SignalR);

export { useSignalR };
export default SignalRProvider;
