import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  mainContent: {
    width: '100%',
    margin: '15px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',

    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

export const AddSpeakeasyJob = ({ createJob }) => {
  const classes = useStyles();
  const [type, setType] = React.useState('');
  const [participantId, setParticipantId] = React.useState('');

  const handleChange = (event) => {
    setType(event.target.value);
  };
  const handleParticipantIdChange = (event) => {
    setParticipantId(event.target.value);
  };

  const types = [
    { name: 'Rebalance', value: 'rebalance' },
    { name: 'Fund Lookup', value: 'fundLookup' },
    { name: 'General', value: 'general' },
    { name: 'Initial Link', value: 'initialLink' },
    { name: 'Relink', value: 'relink' },
  ];

  return (
    <Paper elevation={1} className={classes.content}>
      <Typography variant="h6" className={classes.title}>
        Create Speakeasy Job
      </Typography>
      <div className={classes.mainContent}>
        <TextField
          id="outlined-basic"
          label="Participant ID"
          variant="outlined"
          value={participantId}
          onChange={handleParticipantIdChange}
        />

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            Job Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={type}
            onChange={handleChange}
            label="Job Type"
          >
            {types.map((k, i) => (
              <MenuItem key={k.value} value={k.value}>
                {k.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          disabled={!participantId || !type}
          color="primary"
          onClick={() => createJob(participantId, type)}
        >
          Create
        </Button>
      </div>
    </Paper>
  );
};
