import constate from 'constate';
import config from '../../config';
import { defaultHeaders } from './utils';

const Initialize = () => ({
  register: async (connectionId) => {
    const res = await fetch(`${config.pronvest.api.url}Admin/AddOpsToGroup`, {
      method: 'POST',
      headers: {
        ...defaultHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(connectionId),
    });
    return res.ok;
  },
  getQueueMembers: async () => {
    const res = await fetch(
      `${config.pronvest.api.url}Admin/GetAssistingQueue`,
      {
        method: 'GET',
        headers: defaultHeaders(),
      }
    );
    if (res.ok) {
      return res.json();
    }
    return null;
  },
  assistParticipant: async (connectionId, participantGuid) => {
    const res = await fetch(
      `${config.pronvest.api.url}Admin/participant/${participantGuid}/AssistParticipant`,
      {
        method: 'POST',
        headers: {
          ...defaultHeaders(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(connectionId),
      }
    );
    if (res.ok) {
      return res.json();
    }
    return null;
  },
  finishAssisting: async (participantGuid) => {
    const res = await fetch(
      `${config.pronvest.api.url}Admin/participant/${participantGuid}/FinishAssisting`,
      {
        method: 'POST',
        headers: {
          ...defaultHeaders(),
          'Content-Type': 'application/json',
        },
      }
    );
    return res.ok;
  },
  saveSecurityQuestion: async (participantGuid, question, answer) => {
    const res = await fetch(
      `${config.pronvest.api.url}Admin/participant/${participantGuid}/SecurityQuestion`,
      {
        method: 'PUT',
        headers: {
          ...defaultHeaders(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question,
          answer,
        }),
      }
    );
    return res.ok;
  },
});

const [MFAProvider, useMFA] = constate(Initialize);

export default MFAProvider;
export { useMFA };
