
const base = {
  palette: {
    common: {
      black: '#323232',
      white: '#FFFFFF',
    },
    error: {
      light: '#FFAB00',
      main: '#EE4343',
    },
  },
  typography: {
    h1: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontWeight: 400,
      fontSize: '96px',
      lineHeight: 1.1,
      letterSpacing: '-1.5px',
    },
    h2: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontWeight: 700,
      fontSize: '60px',
      lineHeight: 1.16,
      letterSpacing: '-0.5px',
    },
    h3: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontWeight: 400,
      fontSize: '45px',
      lineHeight: 1.22,
      letterSpacing: '0.5px',
    },
    h4: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontWeight: 700,
      fontSize: '34px',
      lineHeight: 1.29,
      letterSpacing: '0.5px',
    },
    h5: {
      fontWeight: 700,
      fontSize: '25px',
      lineHeight: 1.28,
      letterSpacing: '0',
    },
    h6: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: 1.25,
      letterSpacing: '0.25px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: 1.5,
      letterSpacing: '0.5px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 1.42,
      letterSpacing: '0.25',
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: 1.5,
      letterSpacing: '0.25px',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: 1.42,
      letterSpacing: '0.1px',
    },
    button: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: 1.55,
      letterSpacing: '1px',
      textTransform: 'none',
    },
    caption: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: 1.33,
      letterSpacing: '0.4px',
    },
    overline: {
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: 1.6,
      letterSpacing: '2px',
    },
    useNextVariants: true,
  },
  props: {
    MuiButton: {
      color: 'primary',
      variant: 'contained',
      size: 'large',
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiTextField: {
      fullWidth: true,
      margin: 'normal',
      variant: 'filled',
    },
    MuiLink: {
      underline: 'none',
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      root: {
        borderRadius: '5px',
        '&$focusVisible': {
          boxShadow: 'none',
        },
      },
      label: {
        fontWeight: 500,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '4px',
      },
    },
    MuiExpansionPanel: {
      rounded: {
        '&:last-child': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    MuiIconButton: {
      root: {
        padding: '4px',
      },
    },
    MuiSelect: {
      icon: {
        right: '4px',
      },
    },
    MuiStepper: {
      vertical: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiStepLabel: {
      label: {
        fontWeight: '700 !important',
        fontSize: '16px',
      },
    },
    MuiChip: {
      root: {
        borderRadius: 5,
      },
    },
  },
};

export default base;
