import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import ConfigurePlanSponsorView from './ConfigurePlanSponsor-view';
import { useParticipant, usePlanSponsors } from '../../modules/admin';

const ConfigurePlanSponsorContainer = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('App.ConfigurePlanSponsor');

  const [selected, setSelected] = useState();
  const { participant, getParticipant, switchPlanSponsor } = useParticipant();
  const {
    search,
    searchResults: results,
    createState,
    newPlanSponsor,
    create,
  } = usePlanSponsors();

  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [currentTab, setCurrentTab] = useState('SEARCH');

  useEffect(() => {
    getParticipant(match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchTerm !== '') {
      const timeout = setTimeout(async () => {
        setIsSearching(true);
        await search(searchTerm, planSponsor.distributor.distributorId);
        setIsSearching(false);
      }, 400);
      return () => clearTimeout(timeout);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const swap = async () => {
    if (selected) {
      const swapped = await switchPlanSponsor(match.params.id, selected.guid);
      if (swapped) {
        enqueueSnackbar(t('SwitchSnackSuccess'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('SwitchSnackError'), { variant: 'error' });
      }
    }
  };

  const {
    account: { planSponsor },
  } = participant ?? {
    account: { planSponsor: { feeChargeType: '', planSponsorFeesInfo: {} } },
  };

  const [planSponsorForm, setPlanSponsorForm] = useState({
    planSponsorName: '',
    distributorPlanId: '',
    feeChargeType: planSponsor.feeChargeType,
    billingSchedule:
      planSponsor.planSponsorFeesInfo?.payrollDeductionBillingSchedule ?? '',
  });

  const planSponsorFormValid =
    planSponsorForm.planSponsorName !== '' &&
    planSponsorForm.feeChargeType !== '' &&
    (planSponsorForm.feeChargeType !== 'payroll' ||
      planSponsorForm.billingSchedule !== '');

  const handlePlanSponsorForm = (f, e) => {
    setPlanSponsorForm({
      ...planSponsorForm,
      [f]: e.target.value,
    });
  };

  const [cState, setCState] = useState('init');
  useEffect(() => {
    if (createState === 'created' && cState === 'init') {
      switchPlanSponsor(match.params.id, newPlanSponsor.guid);
      setCState('swapped');
    }
  }, [cState, createState, match.params.id, newPlanSponsor, switchPlanSponsor]);

  if (participant == null) {
    return null;
  }

  const updateSearchTerm = (event) => setSearchTerm(event.target.value);

  return (
    <ConfigurePlanSponsorView
      t={t}
      searchResults={results}
      updateSearchTerm={updateSearchTerm}
      searchTerm={searchTerm}
      isSearching={isSearching}
      selected={selected}
      setSelected={setSelected}
      participant={participant}
      match={match}
      swap={swap}
      tab={currentTab}
      setTab={setCurrentTab}
      planSponsorFormValues={planSponsorForm}
      handlePlanSponsorForm={handlePlanSponsorForm}
      onCreate={create}
      createState={createState}
      planSponsorFormValuesValid={planSponsorFormValid}
    />
  );
};

ConfigurePlanSponsorContainer.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(ConfigurePlanSponsorContainer);
