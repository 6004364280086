import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import RedoIcon from '@material-ui/icons/Redo';
import {
  Box,
  Typography,
  withStyles,
  Grid,
  Divider,
  Modal,
  Paper,
  Container,
} from '@material-ui/core';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useWorkflowRuns } from '../../modules/WorkflowRuns';

const Styles = (theme) => ({
  LogContainer: {
    borderBottom: `1pt solid ${theme.palette.grey[200]}`,
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  LogEntry: {
    cursor: 'pointer',
    padding: theme.spacing(2, 1, 2, 1),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    display: 'flex',
  },
  cardRow: {},
  cardRowCell: {
    flexBasis: '20%',
  },
  ColorBox: {
    width: theme.spacing(1),
    backgroundColor: theme.palette.grey[500],
    marginRight: theme.spacing(2),
  },
  Skipped: {},
  LogDetails: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  displayLine: {
    whiteSpace: 'pre-wrap',
  },
  logBox: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(1),
  },
  panel: {
    height: '32px',
    width: '32px',
    '-webkit-transition': '-webkit-transform .2s ease-in-out',
    '-ms-transition': '-ms-transform .2s ease-in-out',
    transition: 'transform .2s ease-in-out',
  },
  panelOpen: {
    transform: 'rotate(180deg)',
  },
  noFocusBorder: {
    '&:focus': { outline: 'none !important' },
    height: '100vh',
  },
});

const LogListItem = ({ classes, action, runId, runName }) => {
  const run = useWorkflowRuns();
  const [open, setOpen] = useState(false);
  const [io, setIO] = useState();

  useEffect(() => {
    if (open && !io) {
      run.getSpecificRunActionIO(runName, runId, action.name).then((r) => {
        if (r.hasData) {
          setIO(r.json);
        }
      });
    }
  }, [action.name, open, io, run, runId, runName]);

  const duration = useMemo(() => {
    const start = new Date(action['properties.startTime']);
    const end = new Date(action['properties.endTime']);
    return (moment(end).diff(moment(start), 'milliseconds') / 1000).toFixed(2);
  }, [action]);

  const Icon = () => {
    switch (action['properties.status']) {
      case 'Succeeded':
        return <CheckCircleIcon color="secondary" />;
      case 'Skipped':
        return <RedoIcon className={classes.Skipped} />;
      default:
        return <ErrorIcon color="error" />;
    }
  };

  const PrettyPrint = (j) => (
    <div>
      <pre className={classes.displayLine}>
        {typeof j === 'object'
          ? JSON.stringify(j, null, 2)
              .split('\\n')
              .map((l) => (
                <span key={`${l.length}-${Math.random() * 100}`}>
                  {l.replace(/("|"|"|")/g, '')}
                  <br />
                </span>
              ))
          : j}
      </pre>
    </div>
  );

  const InputsContent = () => {
    const i = io && io.inputContent ? JSON.parse(io.inputContent) : null;
    if (i == null || i === undefined) {
      return null;
    }
    const kk = Object.keys(i);
    const iList = kk.map((k) => (
      <Box key={`${k}-i`}>
        <Typography variant="body1">{k}</Typography>
        <Box className={classes.logBox}>
          <Typography variant="caption" className={classes.displayLine}>
            {PrettyPrint(i[k])}
          </Typography>
        </Box>
      </Box>
    ));
    iList.unshift(<Typography variant="h6">Action Inputs</Typography>);
    return iList;
  };

  const OutputsContent = () => {
    const o = io && io.outputContent ? JSON.parse(io.outputContent) : null;
    if (!o) {
      return null;
    }

    if (typeof o.body === 'object') {
      const oList = Object.keys(o.body).map((k) => (
        <Box key={`${k}-o`}>
          <Typography variant="body1">{k}</Typography>
          <Box className={classes.logBox}>
            <Typography variant="caption" className={classes.displayLine}>
              {PrettyPrint(o.body[k])}
            </Typography>
          </Box>
        </Box>
      ));
      oList.unshift(<Typography variant="h6">Action Outputs</Typography>);
      return oList;
    }

    if (typeof o.body === 'string') {
      return (
        <>
          <Typography variant="h6">Action Outputs</Typography>
          <Box key={o.body.slice(0, 100)}>
            <Box className={classes.logBox}>
              <Typography variant="caption" className={classes.displayLine}>
                {PrettyPrint(o.body)}
              </Typography>
            </Box>
          </Box>
        </>
      );
    }

    return null;
  };

  return (
    <Box className={classes.LogContainer}>
      <Box
        className={classes.LogEntry}
        onClick={() => {
          setOpen(true);
        }}
      >
        <Box className={classes.ColorBox} />
        <Box pr={2} display="flex" alignItems="center">
          <Icon />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          pr={4}
          className={classes.cardRowCell}
        >
          <Typography variant="caption" color="textSecondary">
            Start Time
          </Typography>
          <Typography variant="body1">
            {new Date(action['properties.startTime']).toLocaleString()}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          pr={4}
          className={classes.cardRowCell}
        >
          <Typography variant="caption" color="textSecondary">
            End Time
          </Typography>
          <Typography variant="body1">
            {new Date(action['properties.endTime']).toLocaleString()}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          pr={4}
          flexBasis="10%"
          className={classes.cardRow}
        >
          <Typography variant="caption" color="textSecondary">
            Duration
          </Typography>
          <Typography variant="body1">{`${duration}s`}</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="flex-end"
          className={classes.cardRowCell}
        >
          <Typography style={{ fontFamily: 'monospace' }}>
            {action.name}
          </Typography>
        </Box>
        <Box
          display="flex"
          flex="1"
          justifyContent="flex-end"
          alignItems="flex-end"
          className={classes.cardRowCell}
        >
          <ExpandMoreIcon
            className={
              open ? classes.panel : `${classes.panel} ${classes.panelOpen}`
            }
          />
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Container className={classes.noFocusBorder} maxWidth="xl">
          <Box
            width="100%"
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Paper
              style={{ width: '100%', height: '90%', overflowY: 'scroll' }}
            >
              <Box className={classes.LogDetails} mb={1}>
                <Box my={1}>
                  <Typography>{`Status: ${action['properties.status']}`}</Typography>
                  <Typography>{`Code: ${action['properties.code']}`}</Typography>
                  {action['properties.error'] && (
                    <Box mt={1}>
                      <Divider />
                      <Box mt={1}>
                        <Typography>{`Error Condition: ${action['properties.error'].code}`}</Typography>
                        <Typography>{`Error Message: ${action['properties.error'].message}`}</Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                {open && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                      <Box>
                        <InputsContent />
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Box>
                        <OutputsContent />
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Paper>
          </Box>
        </Container>
      </Modal>
    </Box>
  );
};

LogListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  runId: PropTypes.string.isRequired,
  runName: PropTypes.string.isRequired,
};

export default withStyles(Styles)(LogListItem);
