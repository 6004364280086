import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Box, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';

const Styles = (theme) => ({
  cardRow: {
    cursor: 'pointer',
    borderRadius: 0,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  Chippy: {
    minWidth: 90,
    width: 110,
    color: theme.palette.common.white,
    '&.Succeeded': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.Failed': {
      backgroundColor: theme.palette.error.main,
    },
    '&.Running': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

// Single Workflow List Item
const WorkflowListItem = ({ classes, workflow, history }) => (
  // do the get action
  <Card
    onClick={() =>
      history.push(`workflow/${workflow.name}/${workflow.lastRunID}`)
    }
    className={classes.cardRow}
  >
    <Box width="100%" display="flex" alignItems="center">
      <Box flex={1} display="flex" alignItems="center">
        <Box mr={2}>
          <Chip
            className={`${classes.Chippy} ${workflow.state}`}
            label={workflow.state}
          />
        </Box>
        <Box style={{ flex: '1' }} display="flex" flexDirection="column">
          <Typography variant="subtitle1">
            {workflow.tags?.simpleName || workflow.name}
          </Typography>
          <Typography variant="caption">
            {workflow.tags?.simpleDescription}
          </Typography>
        </Box>
      </Box>
      <Box flexBasis="40%" display="flex" justifyContent="space-between">
        <Box display="inline-block" mr={2}>
          <Typography variant="caption">
            {`Last Run: ${new Date(workflow.lastRan).toLocaleString()}`}
          </Typography>
        </Box>
        <Box display="inline-block" minWidth="20%">
          <Typography variant="caption">
            {`Next Run: ${
              workflow.nextRun
                ? new Date(workflow.nextRun).toLocaleString()
                : 'Manual Trigger'
            }`}
          </Typography>
        </Box>
      </Box>
    </Box>
  </Card>
);

WorkflowListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(Styles)(withRouter(WorkflowListItem));
