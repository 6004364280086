import history from '../../modules/history';

import { HUBBLE_SESSION_AUTH } from '../../modules/Login';
import Session from '../../modules/Session';

const defaultHeaders = () => {
  const { token } = Session.get(HUBBLE_SESSION_AUTH) || {};
  return { Authorization: `Bearer ${token}` };
};

const requests = {
  GET: (url) => fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...defaultHeaders(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }),
  DELETE: (url) => fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...defaultHeaders(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }),
  PUT: (url, data) => fetch(url, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(data),
    headers: {
      ...defaultHeaders(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }),
  POST: (url, data) => fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(data),
    headers: {
      ...defaultHeaders(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }),
};

const dispatcherFactory = (state, stateUpdater, fetcherFunc) => () => {
  switch (state) {
    case 'EMPTY':
      stateUpdater().loading();
      fetcher(fetcherFunc).then((d) => {
        if (d.hasData && d.statusCode === 200) {
          stateUpdater(d.json).loaded();
        } else {
          stateUpdater().error();
        }
      });
      break;
    case 'LOADING':
      break;
    case 'LOADED':
      break;
    case 'ERROR':
      break;
    default:
      break;
  }
};

const fetcher = async (fetchFunc) => {
  const res = await fetchFunc();
  if(res.status === 401) {
    // we're logged out, send us back to login!
    history.push('/Oauth')
  }
  const ok = res.status === 200;
  let err = !res.ok;
  let json;
  try {
    json = await res.json();
  } catch {
    err = true;
  }
  if (ok) {
    return returnObjGenerator(json, ok, res.status);
  }
  if (err) {
    return returnObjGenerator(null, err, res.status);
  }
  return returnObjGenerator(null, false, res.status);
};

const stateUpdaterFactory = (updateFunction, stateFunction) => (newValue) => ({
  loading: () => {
    stateFunction('LOADING');
  },
  loaded: () => {
    updateFunction(newValue);
    stateFunction('LOADED');
  },
  error: () => {
    stateFunction('ERROR');
  },
});

const returnObjGenerator = (json, hasData, statusCode) => ({
  hasData,
  statusCode,
  json,
});

const ifLoadedFactory = (data, state) => (cb) => {
  if (state === 'LOADED') {
    cb(data);
  }
};

const dataObjBuilder = (data, state, dispatcher) => ({
  data,
  state,
  dispatch: dispatcher,
  ifLoaded: ifLoadedFactory(data, state),
});

export {
  requests,
  dispatcherFactory,
  fetcher,
  stateUpdaterFactory,
  returnObjGenerator,
  ifLoadedFactory,
  dataObjBuilder,
};
