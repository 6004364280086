import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Box, Radio, Typography, Button, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import { hexToRGB } from '../../utils/ColorUtil';

const Styles = (theme) => ({
  listRowBox: {
    borderWidth: '1pt',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  primaryColor: {
    backgroundColor: hexToRGB(theme.palette.primary.main, 0.35),
    borderColor: theme.palette.primary.main,
  },
  primaryHover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: hexToRGB(theme.palette.primary.main, 0.5),
    },
  },
  secondaryColor: {
    backgroundColor: hexToRGB(theme.palette.secondary.main, 0.35),
    borderColor: theme.palette.secondary.main,
  },
  secondaryHover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: hexToRGB(theme.palette.secondary.main, 0.5),
    },
  },
  errorColor: {
    backgroundColor: hexToRGB(theme.palette.error.main, 0.35),
    borderColor: theme.palette.error.main,
  },
  errorHover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: hexToRGB(theme.palette.error.main, 0.5),
    },
  },
  warningColor: {
    backgroundColor: hexToRGB(theme.palette.error.light, 0.35),
    borderColor: theme.palette.error.light,
  },
  warningHover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: hexToRGB(theme.palette.error.light, 0.5),
    },
  },
  greyColor: {
    backgroundColor: hexToRGB(theme.palette.grey[300], 0.35),
    borderColor: theme.palette.grey[300],
  },
  greyHover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: hexToRGB(theme.palette.grey[300], 0.6),
    },
  },
  whiteColor: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.grey[400],
  },
  whiteHover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
});

const ListRowView = ({
  classes,
  columns,
  radio,
  checkbox,
  add,
  selected,
  paletteColor,
  boxProps,
  onClick,
  onCheck,
  buttonText,
  nodes,
  hasNodes,
}) => {
  let colorClass;
  let hoverClass;
  switch (paletteColor) {
    case 'secondary':
      colorClass = classes.secondaryColor;
      hoverClass = classes.secondaryHover;
      break;
    case 'warning':
      colorClass = classes.warningColor;
      hoverClass = classes.warningHover;
      break;
    case 'error':
      colorClass = classes.errorColor;
      hoverClass = classes.errorHover;
      break;
    case 'grey':
      colorClass = classes.greyColor;
      hoverClass = classes.greyHover;
      break;
    case 'primary':
      colorClass = classes.primaryColor;
      hoverClass = classes.primaryHover;
      break;
    default:
      colorClass = classes.whiteColor;
      hoverClass = classes.whiteHover;
      break;
  }

  return (
    <Box
      my={1}
      p={1}
      className={
        onClick == null
          ? `${classes.listRowBox} ${colorClass}`
          : `${classes.listRowBox} ${colorClass} ${hoverClass}`
      }
      {...boxProps}
      onClick={onClick}
    >
      {add && (
        <Box mr={1}>
          <Button>{buttonText}</Button>
        </Box>
      )}
      {radio && <Radio checked={selected} />}
      {checkbox && <Checkbox onClick={onCheck} checked={selected} />}
      {!hasNodes &&
        columns.map((v, i) => (
          <Box mx={0.25} key={`${v}-${i}-l`} minWidth="120pt" flex="1">
            <Typography variant="body1">{v ? `${v}` : '-'}</Typography>
          </Box>
        ))}
      {hasNodes &&
        nodes.map((v, i) => (
          <Box mx={0.25} key={`${i}-ln`} minWidth="120pt" flex="1">
            {v || '-'}
          </Box>
        ))}
    </Box>
  );
};

ListRowView.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element])
  ),
  paletteColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'grey',
    'white',
  ]).isRequired,
  boxProps: PropTypes.object,
  radio: PropTypes.bool,
  checkbox: PropTypes.bool,
  add: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  onCheck: PropTypes.func,
  buttonText: PropTypes.string,
  nodes: PropTypes.arrayOf(PropTypes.node),
  hasNodes: PropTypes.bool,
};

ListRowView.defaultProps = {
  radio: false,
  checkbox: false,
  selected: false,
  add: false,
  onClick: null,
  onCheck: null,
  buttonText: 'Add',
  nodes: [],
  hasNodes: false,
  columns: [],
  boxProps: null,
};

export default withStyles(Styles)(ListRowView);
