import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography } from '@material-ui/core';

const LoadingView = ({ message }) => (
  <Box
    height="100vh"
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    <Box mb={4}>
      <CircularProgress disableShrink size={100} variant="indeterminate" />
    </Box>
    <Typography variant="h6">{message}</Typography>
  </Box>
);

LoadingView.propTypes = {
  message: PropTypes.string,
};

LoadingView.defaultProps = {
  message: 'Please wait...',
};

export default LoadingView;
