import React, { createContext, useContext, useState } from 'react';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';

import Session from '../../modules/Session';
import {
  healthCheck,
  login,
  LOGIN_STATES,
  HUBBLE_SESSION_AUTH,
} from '../../modules/Login';
import SpeakeasyTradesProvider from '../../modules/Trades/SpeakeasyTrades';
import WorkflowsProvider from '../../modules/Workflows';
import WorkflowRunsProvider from '../../modules/WorkflowRuns';
import {
  PlanSponsorProvider,
  ParticipantProvider,
  DirectDetailsProvider,
  MFAProvider,
  FundBuilderProvider,
} from '../../modules/admin';
import SignalRProvider from '../../modules/SignalR';

import { Light } from '../../themes/index';
import ReportsProvider from '../../modules/Reports';
import { AppContextProvider } from '../../modules/AppContext';

import AppView from './App-view';

const App = () => {
  const { loginStatus, token } = Session.get(HUBBLE_SESSION_AUTH) || {};

  if (!loginStatus && !(token && token !== 'undefined')) {
    login();
    return null;
  }
  if (loginStatus === LOGIN_STATES.loggedin) {
    healthCheck();
  }

  return (
    <AppContextProvider>
      <SpeakeasyTradesProvider>
        <WorkflowsProvider>
          <WorkflowRunsProvider>
            <ReportsProvider>
              <PlanSponsorProvider>
                <ParticipantProvider>
                  <DirectDetailsProvider>
                    <MFAProvider>
                      <SignalRProvider>
                        <FundBuilderProvider>
                          <CssBaseline />
                          <MuiThemeProvider theme={Light}>
                            <AppView />
                          </MuiThemeProvider>
                        </FundBuilderProvider>
                      </SignalRProvider>
                    </MFAProvider>
                  </DirectDetailsProvider>
                </ParticipantProvider>
              </PlanSponsorProvider>
            </ReportsProvider>
          </WorkflowRunsProvider>
        </WorkflowsProvider>
      </SpeakeasyTradesProvider>
    </AppContextProvider>
  );
};
export default App;
