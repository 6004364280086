import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Grid,
  Card,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { mFormat } from '../../utils/Money';
import { useTheme } from '@material-ui/core';

const PlanSponsorReportDocument = ({ report, chartImages }) => {
  const theme = useTheme();
  const Styles = () => ({
    smallRow: {
      fontSize: '0.1in !important',
    },
    fullHeight: {
      height: '100%',
    },
    chart: {
      maxWidth: '100%',
    },
    // eslint-disable-next-line no-useless-computed-key
    ['@media print']: {
      page: {
        position: 'absolute',
        padding: '0.25in',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#FFF',
        left: 0,
        top: 0,
      },
      cardHead: {
        fontSize: '0.4em !important',
      },
      cardValue: {
        fontSize: '1em',
      },
    },
    tableTitle: {
      fontSize: 14,
      color: theme.palette.grey[600],
      fontWeight: 'bold',
      padding: theme.spacing(1),
    },
  });
  const classes = makeStyles(Styles)();

  return (
    <Box>
      <Box className={classes.page}>
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography variant="h5" className={classes.cardValue}>
                  {report.planSponsorName}
                </Typography>
                <Typography variant="overline" className={classes.cardHead}>
                  Plan Sponsor Stats Report
                </Typography>
                <Typography variant="overline" className={classes.cardHead}>
                  {new Date().toLocaleDateString()}
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid xs={3} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h6" className={classes.cardValue}>
                  {report.managedParticipants}
                </Typography>
                <Typography variant="overline" className={classes.cardHead}>
                  Managed Participants
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid xs={3} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h6" className={classes.cardValue}>
                  {report.totalEligibleParticipants}
                </Typography>
                <Typography variant="overline" className={classes.cardHead}>
                  Eligible Participants
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid xs={3} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h6" className={classes.cardValue}>
                  {mFormat(report.managedAssets)}
                </Typography>
                <Typography variant="overline" className={classes.cardHead}>
                  Managed Assets
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid xs={3} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h6" className={classes.cardValue}>
                  {mFormat(report.manageableAssets)}
                </Typography>
                <Typography variant="overline" className={classes.cardHead}>
                  Manageable Assets
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid xs={6} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  className={classes.chart}
                  src={chartImages.ManagedParticipants}
                  alt="Managed Participants Chart"
                />
              </Box>
            </Card>
          </Grid>
          <Grid xs={6} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  className={classes.chart}
                  src={chartImages.ManagedAssets}
                  alt="Managed Participants Chart"
                />
              </Box>
            </Card>
          </Grid>
          <Grid xs={6} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  className={classes.chart}
                  src={chartImages.ManagedByDate}
                  alt="Managed Participants Chart"
                />
              </Box>
            </Card>
          </Grid>
          <Grid xs={6} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  className={classes.chart}
                  src={chartImages.ManagedAssetsByDate}
                  alt="Managed Participants Chart"
                />
              </Box>
            </Card>
          </Grid>
          <Grid xs={6} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  className={classes.chart}
                  src={chartImages.StrategyBreakdown}
                  alt="Managed Participants Chart"
                />
              </Box>
            </Card>
          </Grid>
          <Grid xs={6} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  className={classes.chart}
                  src={chartImages.StrategySummary}
                  alt="Managed Participants Chart"
                />
              </Box>
            </Card>
          </Grid>
          <Grid xs={6} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  className={classes.chart}
                  src={chartImages.GapHired}
                  alt="Managed Participants Chart"
                />
              </Box>
            </Card>
          </Grid>
          <Grid xs={3} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <Box textAlign="center">
                  <Typography variant="h6" className={classes.cardValue}>
                    {report.completedGapHired.length}
                  </Typography>
                  <Typography variant="overline" className={classes.cardHead}>
                    Completed Gap and Hired
                  </Typography>
                </Box>
                <Box textAlign="center">
                  <Typography variant="h6" className={classes.cardValue}>
                    {report.completedGapNotHired.length}
                  </Typography>
                  <Typography variant="overline" className={classes.cardHead}>
                    Completed Gap and Not Hired
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid xs={3} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h6" className={classes.cardValue}>
                  {report.newHireCount}
                </Typography>
                <Typography variant="overline" className={classes.cardHead}>
                  New Hires
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ top: '100vh' }} className={classes.page}>
        <Grid container spacing={1}>
          <Grid xs={6} item>
            <Card className={classes.fullHeight}>
              <Box
                className={classes.fullHeight}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  className={classes.chart}
                  src={chartImages.InteractionSummary}
                  alt="Managed Participants Chart"
                />
              </Box>
            </Card>
          </Grid>
          <Grid xs={12} item>
            <Card className={classes.fullHeight}>
              <Box className={classes.fullHeight} p={2}>
                <Typography className={classes.tableTitle}>
                  Customer Interactions for the last 30 days
                </Typography>
                {report.interactions.length > 0 && (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {Object.keys(report.interactions[0]).map((h, i) => (
                          <TableCell className={classes.smallRow} key={h}>
                            <Typography
                              variant="overline"
                              className={classes.cardHead}
                            >
                              {' '}
                              {
                                [
                                  `Interaction Method`,
                                  `First Name`,
                                  `Last Name`,
                                  `Interaction Date`,
                                ][i]
                              }{' '}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {report.interactions.map((r, j) => (
                        <TableRow key={`${r.participantId}-${j}`}>
                          {Object.values(r).map((c, i) => (
                            <TableCell
                              className={classes.smallRow}
                              key={`${c}-${i}`}
                            >
                              {c}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
                {report.interactions.length === 0 && (
                  <Typography variant="overline" className={classes.cardHead}>
                    No Interactions
                  </Typography>
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

PlanSponsorReportDocument.propTypes = {
  report: PropTypes.object.isRequired,
  chartImages: PropTypes.object.isRequired,
};

export default PlanSponsorReportDocument;
