import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Icon, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  grid: {
    height: '100vh',
  },
  icon: {
    fontSize: '100px',
  },
}));

const ErrorScreen = () => {
  const { t } = useTranslation('App.Error');
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      className={classes.grid}
    >
      <Grid item>
        <Icon color="error" className={classes.icon}>
          error
        </Icon>
      </Grid>
      <Grid item>
        <Box my={2}>
          <Typography variant="h3" paragraph>
            {t('Title')}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="h5">{t('Message')}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{t('SubMessage')}</Typography>
      </Grid>
    </Grid>
  );
};

export default ErrorScreen;
