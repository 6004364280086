import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@material-ui/core';

const HubblePage = ({ children }) => (
  <Box mt={2}>
    <Container maxWidth="xl">{children}</Container>
  </Box>
);
HubblePage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HubblePage;
