import { useState } from 'react';
import constate from 'constate';
import config from '../../config';

const searchFunds = async (term) => {
  const url = new URL(`${config.pronvest.api.url}Admin/SearchFunds`);
  url.searchParams.set('term', term);
  const res = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Authorization: `ApiKey ${config.pronvest.api.apiKey}`,
    },
  });

  if (res.ok) {
    return res.json();
  }

  return [];
};

const Initialize = () => {
  const [parsedFunds, setParsedFunds] = useState();
  const [confirmedFunds, setConfirmedFunds] = useState();
  const [confirmStatus, setConfirmStatus] = useState('init');
  return {
    parsedFunds,
    parseTickers: (tickerList, fundLineupId) => {
      const url = new URL(`${config.pronvest.api.url}Admin/ParseFunds`);
      url.searchParams.set('fundLineupId', fundLineupId);
      const b = {
        tickers: tickerList,
      };
      fetch(url.toString(), {
        body: JSON.stringify(b),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `ApiKey ${config.pronvest.api.apiKey}`,
        },
      }).then(async (d) => {
        setParsedFunds(await d.json());
      });
    },
    confirmedFunds,
    confirmFunds: (diff, fundLineupId) => {
      setConfirmStatus('loading');
      const url = new URL(`${config.pronvest.api.url}Admin/ConfirmFunds`);
      url.searchParams.set('fundLineupId', fundLineupId);
      const b = diff;
      fetch(url.toString(), {
        body: JSON.stringify(b),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `ApiKey ${config.pronvest.api.apiKey}`,
        },
      })
        .then(async (d) => {
          setConfirmedFunds(await d.json());
          setConfirmStatus('done');
        })
        .catch(() => setConfirmStatus('err'));
    },
    confirmStatus,
  };
};

const [FundBuilderProvider, useFundBuilder] = constate(Initialize);

export default FundBuilderProvider;
export { useFundBuilder, searchFunds };
