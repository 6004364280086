import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Card,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Tabs,
  Tab,
  TextField,
  Typography,
  CircularProgress,
  ListItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { DirectAccountDetails, HubblePage, TopNav } from '../../components';
import ListRowView from '../../components/ListRow/ListRow-view';

const ConfigurePlanSponsorView = ({
  t,
  searchResults,
  searchTerm,
  isSearching,
  updateSearchTerm,
  selected,
  setSelected,
  tab,
  setTab,
  participant,
  match,
  swap,
  planSponsorFormValues,
  handlePlanSponsorForm,
  onCreate,
  createState,
  planSponsorFormValuesValid,
}) => {
  const {
    account: { planSponsor },
  } = participant;
  const billingSchedules = t('BillingSchedules', { returnObjects: true });
  const feeChargeTypes = t('FeeChargeTypes', { returnObjects: true });
  return (
    <>
      <TopNav />
      <HubblePage>
        <Typography variant="h4">{t('Heading')}</Typography>
        {participant && (
          <Box mt={2}>
            <Card>
              <Box p={2}>
                <Grid container>
                  <Grid item xs={12} md={3}>
                    <Typography variant="h6" paragraph>
                      {t('PlanSponsorInfo')}
                    </Typography>
                    <Typography variant="caption">
                      {t('PlanSponsorName')}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {planSponsor.planSponsorName}
                    </Typography>
                    <Typography variant="caption">
                      {t('Distributor')}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {planSponsor.distributor.distributorName}
                    </Typography>
                    <Typography variant="caption">
                      {t('Fee Charge Type')}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {feeChargeTypes.find(
                        (x) => x.Value === planSponsor.feeChargeType
                      )?.Label ?? ''}
                    </Typography>
                    {planSponsor?.planSponsorFeesInfo
                      ?.payrollDeductionBillingSchedule && (
                      <>
                        <Typography variant="caption">
                          {t('BillingSchedule')}
                        </Typography>
                        <Typography variant="body1">
                          {billingSchedules.find(
                            (x) =>
                              x.Value ===
                              planSponsor.planSponsorFeesInfo
                                .payrollDeductionBillingSchedule
                          )?.Label ?? ''}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography variant="h6" paragraph>
                      {t('ParticipantInfo')}
                    </Typography>
                    <Box mb={2}>
                      <Typography variant="caption">
                        {t('ParticipantName')}
                      </Typography>
                      <Typography variant="body1">{`${participant.firstName} ${participant.lastName}`}</Typography>
                    </Box>
                    <Typography variant="caption">
                      {t('ParticipantID')}
                    </Typography>
                    <Typography variant="body1">
                      {participant.participantId}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} />
                  <Grid item xs={12} md={3}>
                    <Box display="flex" alignItems="flex-end" height="100%">
                      <Button
                        fullWidth
                        component={RouterLink}
                        to={`/fundlineup/${match.params.id}`}
                      >
                        {t('FundLineUpLink')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
        )}
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <DirectAccountDetails participantGuid={match.params.id} />
            </Grid>
            <Grid item xs={12} md={9}>
              <Card>
                <AppBar position="static">
                  <Tabs value={tab === 'SEARCH' ? 0 : 1}>
                    <Tab
                      label={t('SearchTab')}
                      onClick={() => setTab('SEARCH')}
                    />
                    <Tab
                      label={t('CreateTab')}
                      onClick={() => setTab('CREATE')}
                    />
                  </Tabs>
                </AppBar>
                {tab === 'SEARCH' && (
                  <Box p={2}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel htmlFor="search-term">
                        {t('SearchAction')}
                      </InputLabel>
                      <FilledInput
                        fullWidth
                        id="search-term"
                        type="text"
                        value={searchTerm}
                        onChange={updateSearchTerm}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    {isSearching && (
                      <Box
                        my={3}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress />
                      </Box>
                    )}
                    {!isSearching && searchResults.length > 0 && (
                      <>
                        {searchResults.map(
                          (x) =>
                            x.planSponsorId !==
                              participant.account.planSponsor.planSponsorId && (
                              <ListRowView
                                key={x.planSponsorId}
                                radio
                                columns={[
                                  x.planSponsorId.toString(),
                                  x.planSponsorName,
                                  x.distributorPlanId,
                                ]}
                                paletteColor="primary"
                                onClick={() => {
                                  setSelected(x);
                                }}
                                selected={
                                  x.planSponsorId === selected?.planSponsorId
                                }
                              />
                            )
                        )}
                        <Box mt={2}>
                          <Button
                            disabled={
                              !isSearching &&
                              selected?.planSponsorId ===
                                planSponsor.planSponsorId
                            }
                            onClick={swap}
                          >
                            {t('ConfirmAction')}
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                )}
                {tab === 'CREATE' && (
                  <>
                    {createState === 'init' && (
                      <Box p={2}>
                        <Box mb={2}>
                          <TextField
                            value={planSponsorFormValues.planSponsorName}
                            onChange={(e) =>
                              handlePlanSponsorForm('planSponsorName', e)
                            }
                            label={t('PlanSponsorName')}
                          />
                          <TextField
                            value={planSponsorFormValues.distributorPlanId}
                            onChange={(e) =>
                              handlePlanSponsorForm('distributorPlanId', e)
                            }
                            label={t('DistributorPlanID')}
                          />
                          <TextField
                            select
                            value={planSponsorFormValues.feeChargeType}
                            onChange={(e) =>
                              handlePlanSponsorForm('feeChargeType', e)
                            }
                            label={t('FeeChargeType')}
                          >
                            {feeChargeTypes.map((x) => (
                              <ListItem key={x.Value} value={x.Value}>
                                {x.Label}
                              </ListItem>
                            ))}
                          </TextField>
                          {planSponsorFormValues.feeChargeType ===
                            'payroll' && (
                            <TextField
                              select
                              value={planSponsorFormValues.billingSchedule}
                              onChange={(e) =>
                                handlePlanSponsorForm('billingSchedule', e)
                              }
                              label={t('BillingSchedule')}
                            >
                              {billingSchedules.map((x) => (
                                <ListItem key={x.Value} value={x.Value}>
                                  {x.Label}
                                </ListItem>
                              ))}
                            </TextField>
                          )}
                        </Box>
                        <Button
                          disabled={!planSponsorFormValuesValid}
                          onClick={() => {
                            onCreate(
                              planSponsor.distributorId,
                              planSponsorFormValues.planSponsorName,
                              planSponsorFormValues.distributorPlanId,
                              planSponsorFormValues.feeChargeType,
                              planSponsorFormValues.billingSchedule
                            );
                          }}
                        >
                          {t('CreateTab')}
                        </Button>
                      </Box>
                    )}
                    {createState === 'creating' && (
                      <Box p={2}>
                        <CircularProgress />
                      </Box>
                    )}
                    {createState === 'created' && <Box p={2}>Done!</Box>}
                  </>
                )}
              </Card>
            </Grid>
          </Grid>
        </Box>
      </HubblePage>
    </>
  );
};
ConfigurePlanSponsorView.propTypes = {
  t: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired,
  isSearching: PropTypes.bool.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.object,
  tab: PropTypes.string,
  participant: PropTypes.object,
  match: PropTypes.object.isRequired,
  swap: PropTypes.func.isRequired,
  setTab: PropTypes.func.isRequired,
  planSponsorFormValues: PropTypes.object.isRequired,
  handlePlanSponsorForm: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  planSponsorFormValuesValid: PropTypes.bool.isRequired,
  createState: PropTypes.string.isRequired,
};

ConfigurePlanSponsorView.defaultProps = {
  tab: 'SEARCH',
  selected: null,
  participant: null,
};

export default ConfigurePlanSponsorView;
